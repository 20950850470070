<template src="./patches.html"></template>
<script>
export default {
  name: "Patches",

  created() {
    this.$store.commit("changeTitle", "Graftworx Commands");
  },

  methods: {
    routePatch(patch) {
      if (!patch && !patch.device_id) {
        return;
      }

      this.$router.push({
        name: "Patch",
        params: {
          device_id: patch.device_id,
        },
      });
    },
  },
};
</script>

<style src="./patches.css" scoped></style>
