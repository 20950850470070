<template src="./tags.html"></template>
<script>
import { clone, throttle } from "lodash";

export default {
  name: "Tags",
  props: {
    readID: {
      type: String,
      default: null,
    },

    tags: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => ["invalid"],
    },
  },

  data() {
    return {
      selectedTags: clone(this.tags),
      selectableItems: clone(this.items),
      loading: false,
      search: null,
    };
  },

  watch: {
    search: {
      handler: throttle(function () {
        this.searchTags();
      }, 200),
    },
  },

  created() {
    this.searchTags();
  },

  methods: {
    searchTags() {
      this.$store
        .dispatch("getTags", {
          includes: this.search,
        })
        .then(({ tags }) => {
          this.selectableItems = tags;
        });
    },

    removeTag(item) {
      this.selectedTags.splice(this.selectedTags.indexOf(item), 1);
      this.selectedTags = [...this.selectedTags];

      this.updateTags();
    },

    updateTags() {
      // add new tags to tags table
      const latestIndex = this.selectedTags.length - 1;
      const latest = this.selectedTags[latestIndex];

      if (latest && this.selectableItems.indexOf(latest) < 0) {
        this.$store.dispatch("createTag", latest).then(({ tag }) => {
          this.searchTags();

          this.selectedTags[latestIndex] = tag;

          this.saveAndEmit();
        });
      } else {
        this.saveAndEmit();
      }
    },

    /**
     * If a read ID was provided, save the currently selected tags.
     * Regardless of whether or not there is a read ID, an 'update' event
     * will be emitted, return the selected tags to the parent module.
     */
    saveAndEmit() {
      if (!this.readID) {
        return this.$emit("update", this.selectedTags);
      }

      this.loading = true;
      this.$store
        .dispatch("setTags", {
          readID: this.readID,
          tags: this.selectedTags,
        })
        .then(() => {
          this.loading = false;
          this.$emit("update", this.selectedTags);
        });
    },

    saving(item) {
      return this.loading && item.index === this.selectedTags.length - 1;
    },
  },
};
</script>

<style src="./tags.css" scoped></style>
