<template src="./alert.html"></template>
<script>
export default {
  name: "Alert",
  props: {
    title: {
      type: String,
      default: "Error",
    },
  },

  data() {
    return {
      activate: true,
    };
  },

  watch: {
    activate: {
      handler() {
        if (!this.activate) {
          this.close();
        }
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style src="./alert.css" scoped></style>
