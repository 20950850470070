<template src="./read.html"></template>
<script>
export default {
  name: "Read",
  data() {
    return {
      loading: true,
      filtered: true,
      ambient: false,
      read: {},
      result: {},
      totalResults: 0,
    };
  },

  created() {
    this.filtered = Boolean(this.$route.query.filter) || true;
    this.ambient = Boolean(this.$route.query.ambient) || false;
    this.$store.commit("changeTitle", "Graftworx Charts");
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("fetchRead", {
          read_id: this.$route.query.read_id,
          filter: `${this.filtered}`,
          ambient: `${this.ambient}`,
        })
        .then((response) => {
          this.read = response.data.read;
          this.totalResults = response.data.totalResults;
          this.result = response.data.result;

          this.loading = false;
        });
    },

    toggleFilter() {
      this.filtered = !this.filtered;
      this.getDataFromApi();
    },

    toggleAmbient() {
      this.ambient = !this.ambient;
      this.getDataFromApi();
    },
  },
};
</script>

<style src="./read.css" scoped></style>
