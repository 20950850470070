<template src="./patient-vitals-chart.html"></template>
<script>
import { defaults } from "lodash";
import moment from "moment";
import Highcharts from "highcharts";

export default {
  name: "PatientVitalsChart",

  props: {
    patientID: {
      type: String,
      default: null,
    },

    targetTZ: {
      type: String,
      default: "hub",
    },

    forceChart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      displayChart: false,
      results: [],
      patientChart: null,
      localTZ: new Date().getTimezoneOffset() * -1,
      timezone: "+0000",
    };
  },

  watch: {
    targetTZ: {
      handler() {
        this.buildChart();
      },
    },
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.$store
        .dispatch("fetchPatientVitals", {
          patient_id: this.patientID,
        })
        .then((response) => {
          // Display all hub timezones based on latest read
          const lastResultIndex = response.results.length - 1;
          const tz = response.results[lastResultIndex].timezone;
          this.timezone = tz;

          this.results = response.results;

          this.results.sort((a, b) => {
            if (!a._momentTime) {
              a._momentTime = this.time(a.read_time, a.timezone, true);
            }

            if (!b._momentTime) {
              b._momentTime = this.time(b.read_time, b.timezone, true);
            }

            if (a._momentTime.isAfter(b._momentTime)) {
              return 1;
            }

            if (b._momentTime.isAfter(a._momentTime)) {
              return -1;
            }

            return 0;
          });

          this.displayChart = this.buildChart();
          this.loading = false;
        })
        .catch((err) => {
          //
        });
    },

    buildChart() {
      const metrics = ["hr", "hct", "flow", "spo2"];
      const chartData = {};
      const chartTicks = {};
      const chartAxes = [];
      const chartSeries = [];
      const metricLabels = {
        hr: {
          name: "HR",
          units: "{value:.0f} bpm",
          tooltip: "HR: {point.y:.1f} bpm",
          min: 0,
          max: null,
          deltaY: 20,
        },
        hct: {
          name: "HCT",
          units: "{value:.0f}%",
          tooltip: "HCT: {point.y:.1f}%",
          min: 0,
          max: 100,
          deltaY: 5,
        },
        flow: {
          name: "Flow",
          units: "{value:.0f} mL/min",
          tooltip: "Flow: {point.y:.1f} mL/min",
          min: 100,
          max: 3200,
          deltaY: 500,
        },
        spo2: {
          name: "SpO2",
          units: "{value:.0f}%",
          tooltip: "SpO2: {point.y:.1f}%",
          min: 60,
          max: 100,
          deltaY: 5,
        },
      };

      const metricFilter = {
        spo2: {
          min: 60,
          max: 100,
        },
      };

      metrics.forEach((metric) => {
        chartData[metric] = [];
        chartTicks[metric] = {
          set: false,
          min: 0,
          max: 0,
          positions: [],
        };
      });

      this.results.forEach((result, resultIndex) => {
        let read_timestamp = this.time(
          result._momentTime.format(),
          result.timezone,
          true
        );
        let x_point = read_timestamp && read_timestamp.format("x");

        metrics.forEach((metric) => {
          if (!result[metric]) {
            console.log(`${metric} not found in result ${result["_id"]}`);
            return;
          }

          let vital = result[metric];

          if (!Array.isArray(vital)) {
            return;
          }

          let value = null;

          for (let i = 0; i < vital.length; i++) {
            let channel = vital[i];

            if (channel.source_descriptor !== "average") {
              continue;
            }

            if (!channel.value) {
              return;
            }

            value = channel.value;
          }

          if (!value) {
            return;
          }

          if (metricFilter[metric]) {
            if (value > metricFilter[metric].max) {
              value = metricFilter[metric].max;
            }

            if (value < metricFilter[metric].min) {
              return;
            }
          }

          if (!chartTicks[metric].set) {
            chartTicks[metric].max = value;
            chartTicks[metric].min = value;
          } else if (value > chartTicks[metric].max) {
            chartTicks[metric].max = value;
          } else if (value < chartTicks[metric].min) {
            chartTicks[metric].min = value;
          }

          chartTicks[metric].set = true;

          chartData[metric].push([parseInt(x_point, 10), value]);
        });
      });

      let displayChart = false;

      let middle = metrics.length / 2;
      metrics.forEach((metric, i) => {
        chartTicks[metric].positions = this.calculateTickPositions(
          chartTicks[metric].min,
          chartTicks[metric].max,
          5,
          metricLabels[metric]
        );

        chartAxes.push({
          labels: {
            format: metricLabels[metric].units,
            style: {
              color: Highcharts.getOptions().colors[i],
            },
          },
          title: {
            text: metricLabels[metric].name,
            style: {
              color: Highcharts.getOptions().colors[i],
            },
          },
          opposite: i >= middle,
          tickPositions: chartTicks[metric].positions,
        });

        let xDateTZ = "+0000";

        if (this.targetTZ === "hub") {
          xDateTZ = this.timezone;
        } else if (this.targetTZ === "local") {
          xDateTZ = this.minutesToTz(this.localTZ);
        }

        chartSeries.push({
          name: metricLabels[metric].name,
          yAxis: i,
          data: chartData[metric],
          tooltip: {
            pointFormat: metricLabels[metric].tooltip,
            xDateFormat: `%Y-%b-%d %l:%M %p ${xDateTZ}`,
          },
        });
        displayChart = displayChart || chartData[metric].length > 1;
      });

      this.patientChart = this.hcOptions({
        title: {
          text: "Results",
        },
        yAxis: chartAxes,
        series: chartSeries,
      });

      return displayChart;
    },

    hcOptions(opt = {}) {
      return defaults({}, opt, {
        time: {
          useUTC: false,
        },
        chart: {
          zoomType: "x",
        },
        xAxis: {
          type: "datetime",
          labels: {
            // format: '{value:%Y-%b-%e %l:%M %p}'
          },
          gridLineWidth: 1,
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "Click and drag in the plot area to zoom in"
              : "Pinch the chart to zoom in",
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            lineWidth: 8,
          },
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          xDateFormat: "%Y-%b-%e %l:%M %p",
        },
      });
    },

    calculateTickPositions(min, max, steps = 5, options = {}) {
      if (!min && !max) {
        return [];
      }

      const ticks = [];
      let bottom = min;
      let top = max;

      // Add padding to top and bottom, but keep defined maximum ranges in mind
      if (top < options.max || !options.max) {
        if (options.max) {
          if (options.max > top * 1.1) {
            top *= 1.1;
          } else {
            top = options.max;
          }
        } else {
          top *= 1.1;
        }
      }

      if (bottom > options.min || !options.min) {
        if (options.min) {
          if (options.min < bottom * 0.9) {
            bottom *= 0.9;
          } else {
            bottom = options.min;
          }
        } else {
          bottom *= 0.9;
        }
      }

      // TODO: Verify the window has a minimum size; if not, adjust based on provided max and min
      // Given the adjustment made in the prior steps, this *may* not be necessary
      if (options.deltaY && top - bottom < options.deltaY) {
        // Increase the window
        console.log("increasing window");

        let adjustTop = options.deltaY / 2;
        let adjustBottom = options.deltaY / 2;

        if (options.max) {
          if (top + adjustTop > options.max) {
            let diff = adjustTop + top - options.max;
            adjustTop -= diff;
            adjustBottom += diff;
          }
        }

        if (options.min) {
          if (bottom - adjustBottom < options.min) {
            let diff = bottom - adjustBottom + options.min;
            adjustBottom += diff;
          }
        }

        if (bottom === 0 || bottom - adjustBottom < 0) {
          adjustBottom = 0;
        }

        top += adjustTop;
        bottom -= adjustBottom;
      }

      for (let i = 1; i <= steps; i++) {
        let tick;

        if (i === 1) {
          tick = bottom;
        } else if (i === steps) {
          tick = top;
        } else {
          let delta = top - bottom;
          let stepDelta = delta / (steps - 1);
          tick = bottom + stepDelta * (i - 1);
        }

        ticks.push(tick);
      }

      return ticks;
    },

    tzToMinutes(tz) {
      const sign = tz.charAt(0);
      const hours = parseInt(tz.substr(1, 2));
      const minutes = parseInt(tz.substr(3));
      let total = hours * 60 + minutes;

      if (sign === "-") {
        total *= -1;
      }

      return total;
    },

    minutesToTz(m) {
      let sign = m < 0 ? "-" : "+";
      let offsetMinutes = Math.abs(m);
      let hours = this.pad(Math.floor(offsetMinutes / 60), 2);
      let minutes = this.pad(offsetMinutes % 60, 2);
      return sign + hours + minutes;
    },

    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    time(timeStr, offset, omitTz) {
      if (
        !offset ||
        offset === "unk" ||
        offset === "unknown" ||
        typeof offset !== "string"
      ) {
        if (omitTz) {
          return moment(timeStr).format("YYYY-MM-DD h:mm:ssa");
        }

        return moment(timeStr).format("YYYY-MM-DD h:mm:ssa") + " unk";
      }

      let diff = 0;
      let tz = "";

      switch (this.targetTZ) {
        case "hub":
          diff = this.tzToMinutes(this.timezone);
          tz = this.timezone;
          break;

        case "local":
          tz = this.minutesToTz(this.localTZ);

          break;
        case "utc":
          diff = 0;
          tz = "+0000";
          break;
      }

      if (omitTz) {
        return moment(timeStr).add(diff, "minutes");
      }

      return (
        moment(timeStr).add(diff, "minutes").format("YYYY-MM-DD h:mm:ssa") +
        " " +
        tz
      );
    },
  },
};
</script>

<style src="./patient-vitals-chart.css" scoped></style>
