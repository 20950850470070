<template src="./patient-reads.html"></template>
<script>
import moment from "moment";
import { get } from "lodash";

export default {
  name: "PatientReads",

  filters: {
    avgOrSingle(values) {
      if (!Array.isArray(values)) return;

      const CHANNELS = 2;

      let average = null;
      let value = 0;
      let goodValues = 0;
      let goodValue;

      values.forEach((v, i) => {
        if (v.source_descriptor === "average") {
          average = v.value;
          return;
        }

        if (v.value && !isNaN(v.value)) {
          value += v.value;
          goodValues++;
          goodValue = v;
        }
      });

      if (average && !isNaN(parseFloat(average)) && isFinite(average)) {
        return average.toFixed(2);
      }

      value /= goodValues;

      if (isNaN(value)) {
        return "";
      } else {
        value = value.toFixed(2);
      }

      if (goodValues !== CHANNELS) {
        if (goodValue) {
          value += " " + goodValue.source_descriptor.charAt(0).toUpperCase();
        }
      }

      return value;
    },

    singleTemp(decoded_data) {
      if (!decoded_data) return;

      if (decoded_data.tmp_data) {
        if (
          !decoded_data.tmp_data.temp_on_fistula &&
          !decoded_data.tmp_data.temp_off_fistula
        )
          return;

        const on = Math.abs(decoded_data.tmp_data.temp_on_fistula);
        const off = Math.abs(decoded_data.tmp_data.temp_off_fistula);

        return Math.max(on, off);
      } else if (decoded_data.accum_temp_data) {
        const tempArray = decoded_data.accum_temp_data.temps;
        const temps = [];

        if (!tempArray) return;

        for (const tempChannel of tempArray) {
          if (!Array.isArray(tempChannel)) {
            continue;
          }

          for (const t of tempChannel) {
            if (typeof t === "number") {
              temps.push(t);
            }
          }
        }

        if (temps.length) {
          return (
            (temps.reduce((a, b) => a + b) / temps.length).toFixed(1) + " (avg)"
          );
        }
      } else {
        // Check frame 0 for temperature data
        let temp_on_fistula = get(
          decoded_data,
          "sensor_frames[0].temp_on_fistula"
        );
        let temp_off_fistula = get(
          decoded_data,
          "sensor_frames[0].temp_off_fistula"
        );

        temp_on_fistula = Math.abs(temp_on_fistula);
        temp_off_fistula = Math.abs(temp_off_fistula);

        return Math.max(temp_on_fistula, temp_off_fistula);
      }
    },

    hrMUSIC(values) {
      if (!Array.isArray(values)) return;
      let last_value = values[values.length - 1];
      return last_value.toFixed(2);
    },

    id(values) {
      if (!values) return;
      return values.slice(-8);
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      hubAssigned: null,
      lightStatus: null,
      localTZ: new Date().getTimezoneOffset() * -1,
      targetTZ: "hub",
      totalReads: 0,
      pages: 0,
      performOnConfirm: null,
      confirmActionDialog: false,
      groundTruthDialog: false,
      activeReadID: null,
      activeReadIndex: null,
      activeRow: null,
      tagFilter: null,
      displayChart: false,
      patientChart: {},
      pagination: {
        rowsPerPage: 100,
        descending: true,
      },
      tags: [],
      reads: [],
      selectedReads: [],
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [
        { text: "Read Time", value: "decoded_data.daq_header.datetime" },
        { text: "Posting Time", value: "mq_data.posting_timestamp" },
        { text: "Ref. Patient", value: "reference_values.patient_id" },
        { text: "Patch", value: "mq_data.patch_ble_mac" },
        { text: "Temp", value: "decoded_data.tmp_data", sortable: false },
        { text: "Acq. #", value: "decoded_data.daq_header.acquisition_number" },
        {
          text: "Battery",
          value: "decoded_data.battery_data",
          sortable: false,
        },
        { text: "MUSIC HR", sortable: false },
        { text: "Framer HR", sortable: false },
        { text: "HCT", sortable: false },
        { text: "SpO2", sortable: false },
        { text: "Tags", value: "tags", sortable: true },
        { text: "Ground Truth", sortable: false },
      ],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },

    selectedReads: {
      handler() {
        if (this.selectedReads.length && this.activeRow) {
          this.activeRow.expanded = false;
        }
      },
    },

    tagFilter: {
      handler() {
        this.getDataFromApi();
      },
    },
  },

  mounted() {
    this.$store.commit("changeTitle", "Graftworx Reads");
    this.getPatientLightStatus();
  },

  methods: {
    getDataFromApi() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.rowsPerPage,
        sort: this.pagination.sortBy,
        descending: this.pagination.descending,
      };

      if (this.tagFilter === "invalid") {
        params.includes = "invalid";
      } else if (this.tagFilter === "valid") {
        params.excludes = "invalid";
      }

      this.loading = true;
      this.$store
        .dispatch("fetchPatientReads", {
          patient_id: this.$route.params.patient_id,
          params: params,
        })
        .then((response) => {
          this.reads = response.data.reads;

          this.reads.forEach((r, i) => {
            r.results = response.data.results.find(
              (s) => r._id["$oid"] === s._id
            );
            r.id = this.$get(r, "_id.$oid");
          });

          this.totalReads = response.data.total;
          this.pages = response.data.pages;
          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    getPatientLightStatus() {
      this.$store
        .dispatch("fetchLightStatus", {
          patientID: this.$route.params.patient_id,
        })
        .then((response) => {
          for (const device of response.devices) {
            if (device.patientID === this.$route.params.patient_id) {
              this.hubAssigned = device.light_status !== "unknown";
              this.lightStatus = device.light_status;
              return;
            }
          }
        })
        .catch((err) => {
          // TODO: Display an error message or something
        });
    },

    displayTZ(selection) {
      this.targetTZ = selection;
    },

    tzToMinutes(tz) {
      const sign = tz.charAt(0);
      const hours = parseInt(tz.substr(1, 2));
      const minutes = parseInt(tz.substr(3));
      let total = hours * 60 + minutes;

      if (sign === "-") {
        total *= -1;
      }

      return total;
    },

    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    readTime(read) {
      if (!read) return;

      let timeStr;

      if (
        read.decoded_data.daq_header &&
        read.decoded_data.daq_header.datetime
      ) {
        timeStr = read.decoded_data.daq_header.datetime;
      } else if (
        read.decoded_data.accum_temp_data &&
        read.decoded_data.accum_temp_data.temps
      ) {
        if (
          Array.isArray(read.decoded_data.accum_temp_data.temps) &&
          read.decoded_data.accum_temp_data.temps.length &&
          Array.isArray(read.decoded_data.accum_temp_data.temps[0]) &&
          read.decoded_data.accum_temp_data.temps[0].length &&
          typeof read.decoded_data.accum_temp_data.temps[0][0] === "string"
        ) {
          let timestamp = read.decoded_data.accum_temp_data.temps[0][0];

          const timestampFormats = [
            moment.ISO_8601,
            "ddd MMM  D HH:mm:ss YYYY",
            "ddd MMM D HH:mm:ss YYYY",
          ];
          timeStr = moment(timestamp, timestampFormats).valueOf();

          if (isNaN(timeStr)) {
            return "n/a";
          }
        } else {
          return "n/a";
        }
      } else {
        return "n/a";
      }

      return this.time(timeStr, this.$get(read, "mq_data.hub_tz_offset"));
    },

    time(timeStr, offset, omitTz) {
      if (
        !offset ||
        offset === "unk" ||
        offset === "unknown" ||
        typeof offset !== "string"
      ) {
        return moment(timeStr).format("YYYY-MM-DD h:mm:ssa") + " unk";
      }

      let diff = 0;
      let tz = "";

      switch (this.targetTZ) {
        case "hub":
          diff = this.tzToMinutes(offset);
          tz = offset;
          break;

        case "local":
          diff = new Date(timeStr).getTimezoneOffset() * -1;

          let sign = diff < 0 ? "-" : "+";
          let offsetMinutes = Math.abs(diff);
          let hours = this.pad(Math.floor(offsetMinutes / 60), 2);
          let minutes = this.pad(offsetMinutes % 60, 2);
          tz = sign + hours + minutes;

          break;
        case "utc":
          diff = 0;
          tz = "+0000";
          break;
      }

      if (omitTz) {
        return moment(timeStr).add(diff, "minutes");
      }

      return (
        moment(timeStr).add(diff, "minutes").format("YYYY-MM-DD h:mm:ssa") +
        " " +
        tz
      );
    },

    missingChannel(set) {
      //TODO: Configure for patches with more channels
      const CHANNELS = 2;

      if (!Array.isArray(set)) {
        return false;
      }

      if (set.length !== CHANNELS) {
        return false;
      }

      let missingAverage = true;
      let badChannel = false;

      set.forEach((v) => {
        if (v.source_descriptor === "average") {
          missingAverage = false;
        }

        if (!badChannel) {
          badChannel = !v.value || isNaN(v.value);
        }
      });

      return badChannel || missingAverage;
    },

    loadGroundTruthEditor(index) {
      this.activeReadIndex = index;
      this.activeReadID = this.$get(this.reads[index], "_id.$oid");
      this.groundTruthDialog = true;
    },

    setGroundTruth(referenceValues) {
      this.groundTruthDialog = false;
      this.reads[this.activeReadIndex].reference_values = referenceValues;
    },

    batchAddTags() {
      if (!this.tags.length) {
        return;
      }

      const readIDs = this.selectedReads.map((r) => this.$get(r, "_id.$oid"));

      this.loading = true;
      this.$store
        .dispatch("batchAddTags", {
          tags: this.tags,
          reads: readIDs,
        })
        .then((tags) => {
          this.selectedReads.map((read) => {
            if (!read.tags) {
              read.tags = [];
            }

            // Only push new tags
            read.tags.push(
              ...tags.filter((i) => {
                return read.tags.indexOf(i) < 0;
              })
            );
          });

          this.selectedReads = [];
          this.loading = false;
        });
    },

    batchReplaceTags() {
      if (!this.tags.length) {
        return;
      }

      const readIDs = this.selectedReads.map((r) => this.$get(r, "_id.$oid"));

      this.loading = true;
      this.$store
        .dispatch("batchReplaceTags", {
          tags: this.tags,
          reads: readIDs,
        })
        .then((tags) => {
          this.selectedReads.map((read) => {
            read.tags = [...tags];
          });

          this.selectedReads = [];
          this.loading = false;
        });
    },

    batchClearTags() {
      const readIDs = this.selectedReads.map((r) => this.$get(r, "_id.$oid"));

      this.loading = true;
      this.$store
        .dispatch("batchReplaceTags", {
          tags: [],
          reads: readIDs,
        })
        .then((tags) => {
          this.selectedReads.map((read) => {
            read.tags = [];
          });

          this.selectedReads = [];
          this.loading = false;
        });
    },

    confirm(performOnConfirm) {
      this.confirmActionDialog = true;
      this.performOnConfirm = performOnConfirm;
    },

    confirmation(proceed) {
      if (proceed) {
        this[this.performOnConfirm]();
      }

      this.confirmActionDialog = false;
    },

    /**
     * Since this table has both an expansion slot and a selector,
     * check what element was clicked on.  Without this check,
     * clicking on the select box also expands the row, which was
     * a really unsatisfying UX.
     *
     * @param e The click event
     * @param p The row prop object
     */
    clickRow(e, p) {
      if (e.path[0].tagName === "TD" && !this.selectedReads.length) {
        p.expanded = !p.expanded;

        this.activeRow = p;
      }
    },

    getBatteryData(read) {
      if (!read || !read.decoded_data) return;

      if (read.decoded_data.battery_data) return read.decoded_data.battery_data;

      return this.$get(read, "decoded_data.sensor_frames[0].battery_data");
    },

    onOrOff() {
      return this.lightStatus === "ok" ? "on" : "off";
    },

    toggleLight() {
      this.$store
        .dispatch("updateLightStatus", {
          patientID: this.$route.params.patient_id,
          status: this.lightStatus === "ok" ? "change" : "ok",
        })
        .then((response) => {
          for (const device of response.devices) {
            if (device.patientID === this.$route.params.patient_id) {
              this.lightStatus = device.light_status;
              return;
            }
          }
        })
        .catch((err) => {
          // TODO: Display an error message or something
        });
    },
  },
};
</script>

<style src="./patient-reads.css" scoped></style>
