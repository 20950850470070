<template src="./graftworxTable.html"></template>
<script>
import moment from "moment";

export default {
  name: "GraftworxTable",

  props: {
    label: {
      type: String,
      default: "Graftworx",
    },
    columns: {
      type: Array,
      default: () => {
        return ["patient_id", "issue", "status", "action"];
      },
    },
    color: {
      type: Object,
      default: () => {
        return {
          primary: "#38383d",
          secondary: "#818184",
        };
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 400,
    },
    disabled: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    searchbar: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  computed: {
    headerStyle() {
      return {
        background: `linear-gradient(to right, ${this.color.primary}, ${this.color.secondary})`,
      };
    },

    cardStyle() {
      if (this.disabled) {
        return {
          opacity: 0.2,
        };
      }
      return {};
    },
  },

  methods: {
    tzToMinutes(tz) {
      const sign = tz.charAt(0);
      const hours = parseInt(tz.substr(1, 2));
      const minutes = parseInt(tz.substr(3));
      let total = hours * 60 + minutes;

      if (sign === "-") {
        total *= -1;
      }

      return total;
    },

    tzMinutesToHours(tz) {
      let sign = tz < 0 ? "-" : "+";
      let offsetMinutes = Math.abs(tz);
      let hours = this.pad(Math.floor(offsetMinutes / 60), 2);
      let minutes = this.pad(offsetMinutes % 60, 2);
      return sign + hours + minutes;
    },

    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    /**
     * Verify whether or not a given offset is in a valid format.
     * An offset should start with a + or - and then have four digits.
     * The first two digits represent hours (with leading zero of necessary)
     * and the second two digits are minutes (also with leading zero if necessary).
     * Optionally, a colon may be present between the hours and minutes.
     * @param offset
     * @returns {boolean}
     */
    goodOffset(offset) {
      return !(
        !offset ||
        typeof offset !== "string" ||
        offset === "unk" ||
        offset === "unknown" ||
        offset.length !== 5 ||
        (offset[0] !== "+" && offset[0] !== "-") ||
        isNaN(parseInt(offset.substring(1)))
      );
    },

    /**
     * Removes the colon from an offset, if present.
     * @param offset
     * @returns {*}
     */
    formatOffset(offset) {
      if (!offset) {
        return;
      }

      return offset.replace(":", "");
    },

    time(obj) {
      if (!obj || !obj.read_timestamp) {
        return;
      }

      let timeStr = obj.read_timestamp;
      let offset = this.formatOffset(obj.offset);

      if (!this.goodOffset(offset)) {
        return (
          moment(timeStr).format("YYYY-MM-DD h:mm:ssa") +
          " " +
          this.tzMinutesToHours(moment().utcOffset())
        );
      }

      let diff = this.tzToMinutes(offset);

      return (
        moment(timeStr).add(diff, "minutes").format("YYYY-MM-DD h:mm:ssa") +
        " " +
        offset
      );
    },
  },
};
</script>

<style src="./graftworxTable.css" scoped></style>
