import Vue from "vue";
import Router from "vue-router";
import Redirect from "@/components/Redirect";
import Dashboard from "@/views/Dashboard/Dashboard";
import Login from "@/views/User/Login/Login";
import Subjects from "@/views/Reads/Subjects/Subjects";
import Subject from "@/views/Reads/Subject/Subject";
import Read from "@/views/Reads/Read/Read";
import PatientList from "@/views/PatientReads/PatientList/PatientList";
import PatientRead from "@/views/PatientReads/PatientRead/PatientRead";
import PatientReads from "@/views/PatientReads/PatientReads/PatientReads";
import Patches from "@/views/Commands/Patches/Patches";
import Patch from "@/views/Commands/Patch/Patch";
import PendingCommands from "@/views/Commands/PendingCommands/PendingCommands";
import CommandStatus from "@/views/Commands/CommandStatus/CommandStatus";
import Patients from "@/views/Patients/Patients";
import Dialysis from "@/views/Demo/Dialysis/Dialysis";
import TagsEditor from "@/views/TagsEditor/TagsEditor";
import UserManagement from "@/views/User/UserManagement/UserManagement";
import Profile from "@/views/User/Profile/Profile";
import Hubs from "@/views/Devices/Hubs/Hubs";
import HubCommand from "@/views/Devices/HubCommand/HubCommand";
import HubCommands from "@/views/Devices/HubCommands/HubCommands";
import HubCommandStatus from "@/views/Devices/HubCommandStatus/HubCommandStatus";
import HubPendingCommands from "@/views/Devices/HubPendingCommands/HubPendingCommands";
import Keys from "@/views/Keys/Keys";
import WebSSH from "@/views/WebSSH/WebSSH";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    // Redirect from old /portal-frontend prefix to non-prefixed path
    {
      path: "/portal-frontend",
      component: Redirect,
    },
    {
      path: "/portal-frontend/:a",
      component: Redirect,
    },
    {
      path: "/portal-frontend/:a/:b",
      component: Redirect,
    },
    {
      path: "/portal-frontend/:a/:b/:c",
      component: Redirect,
    },
    // Basic
    {
      path: "/",
      name: "Index",
      component: Dashboard,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    // Reads
    {
      path: "/subjects",
      name: "Subjects",
      component: Subjects,
    },
    {
      path: "/subjects/:device_id",
      name: "Subject",
      component: Subject,
    },
    {
      path: "/read",
      name: "Read",
      component: Read,
    },
    // Reads by patient
    {
      path: "/patientlist",
      name: "PatientList",
      component: PatientList,
    },
    {
      path: "/patientreads/:patient_id",
      name: "PatientReads",
      component: PatientReads,
    },
    {
      path: "/patientread/:patient_id/:read_id",
      name: "PatientRead",
      component: PatientRead,
    },
    // Commands
    {
      path: "/patches",
      name: "Patches",
      component: Patches,
    },
    {
      path: "/patches/:device_id",
      name: "Patch",
      component: Patch,
    },
    {
      path: "/patches/:device_id/commands",
      name: "PendingCommands",
      component: PendingCommands,
    },
    {
      path: "/patches/:device_id/commandstatus",
      name: "CommandStatus",
      component: CommandStatus,
    },
    // Hub Commands
    {
      path: "/hub-commands",
      name: "HubCommands",
      component: HubCommands,
    },
    {
      path: "/:routed_from/:device_id",
      name: "HubCommand",
      component: HubCommand,
    },
    {
      path: "/:routed_from/:device_id/hubcommandstatus",
      name: "HubCommandStatus",
      component: HubCommandStatus,
    },
    {
      path: "/:routed_from/:device_id/hubcommand",
      name: "HubPendingCommands",
      component: HubPendingCommands,
    },
    // Data editors
    {
      path: "/patients",
      name: "Patients",
      component: Patients,
    },
    {
      path: "/tags",
      name: "Tags",
      component: TagsEditor,
    },
    {
      path: "/users",
      name: "Users",
      component: UserManagement,
    },
    {
      path: "/hubs",
      name: "Hubs",
      component: Hubs,
    },
    {
      path: "/keys",
      name: "Keys",
      component: Keys,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    // Special functionality
    {
      path: "/ssh",
      name: "WebSSH",
      component: WebSSH,
    },
    // Demos
    {
      path: "/dialysis",
      name: "Dialysis",
      component: Dialysis,
    },
  ],
});
