<template>
  <div></div>
</template>
<script>
export default {
  name: "Redirect",

  created() {
    let path = "";

    if (this.$route.params.a) {
      path += "/" + this.$route.params.a;
    }

    if (this.$route.params.b) {
      path += "/" + this.$route.params.b;
    }

    if (this.$route.params.c) {
      path += "/" + this.$route.params.c;
    }

    if (this.$route.query) {
      let first = true;

      for (let key in this.$route.query) {
        let prefix = first ? "?" : "&";

        path += prefix + key + "=" + this.$route.query[key];

        first = false;
      }
    }

    window.location.href = window.location.origin + path;
  },
};
</script>
