<template>
  <v-container fluid>
    <div align="center">
      <input
        id="tzHub"
        type="radio"
        name="tz"
        value="tzHub"
        checked
        @click="displayTZ('hub')"
      />
      <label for="tzHub">Hub time</label>

      <input
        id="tzLocal"
        type="radio"
        name="tz"
        value="tzLocal"
        @click="displayTZ('local')"
      />
      <label for="tzLocal">Local time</label>

      <input
        id="tzUtc"
        type="radio"
        name="tz"
        value="tzUtc"
        @click="displayTZ('utc')"
      />
      <label for="tzUtc">UTC</label>
    </div>
    <table border="1">
      <tr>
        <td>MAC Address</td>
        <td>{{ $get(read, "mq_data.patch_ble_mac") }}</td>
      </tr>
      <tr>
        <td>Read time</td>
        <td>{{ readTime(read) }}</td>
      </tr>
      <tr>
        <td>Read ID</td>
        <td>{{ $get(read, "_id.$oid") }}</td>
      </tr>
      <tr v-if="$get(read, 'decoded_header.packet_version') !== 3">
        <td>Mic Gain</td>
        <td>{{ $get(read, "decoded_data.mic_daq_params.gain", "-") }}</td>
      </tr>
      <tr v-if="$get(read, 'decoded_header.packet_version') !== 3">
        <td>PPG Gain (l/r)</td>
        <td>
          {{
            $get(read, "decoded_data.common_ppg_daq_parameter.left.gain", "-")
          }}
          /
          {{
            $get(read, "decoded_data.common_ppg_daq_parameter.right.gain", "-")
          }}
        </td>
      </tr>
      <tr v-if="getTemp(read, 'temp_on_fistula')">
        <td>Temp on fistula</td>
        <td>{{ getTemp(read, "temp_on_fistula") | abs }}</td>
      </tr>
      <tr v-if="getTemp(read, 'temp_off_fistula')">
        <td>Temp off fistula</td>
        <td>{{ getTemp(read, "temp_off_fistula") | abs }}</td>
      </tr>
    </table>
    <v-flex xs6 offset-xs3>
      <tags :read-i-d="$get(read, '_id.$oid')" :tags="$get(read, 'tags')">
      </tags>
    </v-flex>
    <table
      v-if="$get(read, 'decoded_header.packet_version') !== 3"
      border="1"
      align="center"
      class="wide"
    >
      <thead>
        <tr>
          <th>Metric</th>
          <th>Left</th>
          <th>Right</th>
          <th>Avg</th>
          <th>Reference</th>
          <th>% Diff</th>
        </tr>
      </thead>
      <tr>
        <td><b>Heart Rate</b></td>
        <td>{{ $get(result, "hr[1].value") | sigFigs }}</td>
        <td>{{ $get(result, "hr[0].value") | sigFigs }}</td>
        <td>{{ $get(result, "hr") | avgResult }}</td>
        <td>{{ $get(read, "reference_values.hr") }}</td>
        <td>
          <!-- TODO: avg obj -->
          {{
            percentDiff(
              avg($get(result, "hr[1].value"), $get(result, "hr[0].value")),
              $get(read, "reference_values.hr")
            )
          }}
        </td>
      </tr>
      <tr>
        <td><b>HCT</b></td>
        <td>{{ $get(result, "hct[1].value") | sigFigs }}</td>
        <td>{{ $get(result, "hct[0].value") | sigFigs }}</td>
        <td>{{ $get(result, "hct") | avgResult }}</td>
        <td>{{ $get(read, "reference_values.hct") }}</td>
        <td>
          <!-- TODO: avg obj -->
          {{
            percentDiff(
              avg($get(result, "hct[1].value"), $get(result, "hct[0].value")),
              $get(read, "reference_values.hct")
            )
          }}
        </td>
      </tr>
      <tr>
        <td><b>HGB</b></td>
        <td>{{ $get(result, "hgb[1].value") | sigFigs }}</td>
        <td>{{ $get(result, "hgb[0].value") | sigFigs }}</td>
        <td>{{ $get(result, "hgb") | avgResult }}</td>
        <td>{{ $get(read, "reference_values.hgb") }}</td>
        <td>
          <!-- TODO: avg obj -->
          {{
            percentDiff(
              avg($get(result, "hgb[1].value"), $get(result, "hgb[0].value")),
              $get(read, "reference_values.hgb")
            )
          }}
        </td>
      </tr>
      <tr>
        <td><b>Flow</b></td>
        <td>{{ $get(result, "flow[1].value") | sigFigs }}</td>
        <td>{{ $get(result, "flow[0].value") | sigFigs }}</td>
        <td>{{ $get(result, "flow") | avgResult }}</td>
        <td>{{ $get(read, "reference_values.flow") }}</td>
        <td>
          <!-- TODO: avg obj -->
          {{
            percentDiff(
              avg($get(result, "flow[1].value"), $get(result, "flow[0].value")),
              $get(read, "reference_values.flow")
            )
          }}
        </td>
      </tr>
    </table>

    <br />

    <table border="1" align="center" class="wide">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Value</th>
        </tr>
      </thead>
      <tr v-if="$get(read, 'decoded_header.packet_version') !== 3">
        <td><b>SpO2</b></td>
        <td>
          {{
            spo2max(
              $get(result, "spo2[1].value"),
              $get(result, "spo2[0].value")
            )
          }}
        </td>
      </tr>
      <tr v-else>
        <td><b>HR</b></td>
        <td>{{ $get(result, "hr_music.hr") | hrMUSIC }}</td>
      </tr>
    </table>

    <table border="0" align="center">
      <tr>
        <td>
          <v-btn @click="groundTruthDialog = true"> Edit ground truth </v-btn>
        </td>
      </tr>
      <tr>
        <td>
          <v-btn @click="toggleFilter()"> {{ link.text }} </v-btn>
          <v-btn @click="toggleAmbient()"> {{ ambientLink.text }} </v-btn>
        </td>
      </tr>
    </table>

    <iris-read-charts
      v-if="$get(read, 'decoded_header.packet_version') === 2"
      :read="read"
    >
    </iris-read-charts>

    <tuvalu-read-charts
      v-else-if="$get(read, 'decoded_header.packet_version') === 3"
      :read="read"
      :target-t-z="targetTZ"
    >
    </tuvalu-read-charts>

    <iris-read-charts v-else :read="read"> </iris-read-charts>

    <v-dialog v-model="groundTruthDialog" persistent no-click-animation>
      <ground-truth
        v-if="groundTruthDialog"
        :read-i-d="$get(read, '_id.$oid')"
        :reference="$get(read, 'reference_values')"
        @save="setGroundTruth"
        @cancel="groundTruthDialog = false"
      >
      </ground-truth>
    </v-dialog>
  </v-container>
  <!--  <tuvalu-read-charts :tz="diff" :target-t-z="targetTZ"></tuvalu-read-charts>-->
</template>
<script>
import { defaults, get } from "lodash";
import moment from "moment";
import TuvaluReadCharts from "@/components/ReadCharts/Tuvalu/TuvaluReadCharts.vue";

export default {
  name: "ReadDisplay",

  components: {
    TuvaluReadCharts,
  },

  filters: {
    spo2Range(value) {
      if (typeof value !== "number") {
        return;
      }

      const MIN = 60;
      const MAX = 100;

      if (value < MIN) {
        return;
      }

      if (value > MAX) {
        return MAX + "%";
      }

      return value + "%";
    },

    hrMUSIC(values) {
      if (!Array.isArray(values)) return;
      let last_value = values[values.length - 1];
      return last_value.toFixed(2);
    },
  },

  props: {
    read: {
      type: Object,
      default: () => {},
    },
    result: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      link: {
        text: "Remove PPG bandpass filter",
      },
      ambientLink: {
        text: "Subtract ambient",
      },
      localTZ: new Date().getTimezoneOffset() * -1,
      targetTZ: "hub",
      results: [],
      totalResults: 0,
      charts: [],
      groundTruthDialog: false,
    };
  },

  computed: {
    diff() {
      return this.diff;
    },
  },

  created() {
    if (!this.filtered) {
      this.link.text = "Bandpass filter PPG";
    }
    if (!this.ambient) {
      this.ambientLink.text = "Toggle ambient subtraction";
    }
  },

  methods: {
    avg(a, b) {
      if (!a || !b) return;

      return (a + b) / 2;
    },

    spo2max(a, b) {
      const MIN = 60;
      const MAX = 100;
      let greaterValue = 0;

      if (a > greaterValue) {
        greaterValue = a;
      }

      if (b > greaterValue) {
        greaterValue = b;
      }

      if (greaterValue < MIN) {
        return;
      }

      if (greaterValue > MAX) {
        greaterValue = MAX;
      }

      if (
        greaterValue &&
        !isNaN(parseFloat(greaterValue)) &&
        isFinite(greaterValue)
      ) {
        greaterValue = greaterValue.toFixed(1);
      }

      return greaterValue + "%";
    },

    percentDiff(a, b) {
      if (!a || !b || isNaN(a) || isNaN(b)) {
        return;
      }

      const diff = ((a - b) / b) * 100;

      return diff.toFixed(1);
    },

    displayTZ(selection) {
      this.targetTZ = selection;
    },

    tzToMinutes(tz) {
      const sign = tz.charAt(0);
      const hours = parseInt(tz.substr(1, 2));
      const minutes = parseInt(tz.substr(3));
      let total = hours * 60 + minutes;

      if (sign === "-") {
        total *= -1;
      }

      return total;
    },

    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    readTime(read) {
      if (!read) return;

      let timeStr;

      if (
        read.decoded_data.daq_header &&
        read.decoded_data.daq_header.datetime
      ) {
        timeStr = read.decoded_data.daq_header.datetime;
      } else if (
        read.decoded_data.accum_temp_data &&
        read.decoded_data.accum_temp_data.temps
      ) {
        if (
          Array.isArray(read.decoded_data.accum_temp_data.temps) &&
          read.decoded_data.accum_temp_data.temps.length &&
          Array.isArray(read.decoded_data.accum_temp_data.temps[0]) &&
          read.decoded_data.accum_temp_data.temps[0].length &&
          typeof read.decoded_data.accum_temp_data.temps[0][0] === "string"
        ) {
          let timestamp = read.decoded_data.accum_temp_data.temps[0][0];

          const timestampFormats = [
            moment.ISO_8601,
            "ddd MMM  D HH:mm:ss YYYY",
            "ddd MMM D HH:mm:ss YYYY",
          ];
          timeStr = moment(timestamp, timestampFormats).valueOf();

          if (isNaN(timeStr)) {
            return "n/a";
          }
        } else {
          return "n/a";
        }
      } else {
        return "n/a";
      }

      return this.time(timeStr, this.$get(read, "mq_data.hub_tz_offset"));
    },

    time(timeStr, offset) {
      if (!timeStr) {
        timeStr = get(this.read, "decoded_data.daq_header.datetime");
      }

      if (!offset) {
        offset = get(this.read, "mq_data.hub_tz_offset");
      }

      if (
        !offset ||
        typeof offset !== "string" ||
        offset === "unk" ||
        offset === "unknown"
      ) {
        return moment(timeStr).format("YYYY-MM-DD h:mm:ssa") + " unk";
      }

      let diff = 0;
      let tz = "";

      switch (this.targetTZ) {
        case "hub":
          diff = this.tzToMinutes(offset);
          tz = offset;
          break;

        case "local":
          diff = new Date(timeStr).getTimezoneOffset() * -1;

          let sign = diff < 0 ? "-" : "+";
          let offsetMinutes = Math.abs(diff);
          let hours = this.pad(Math.floor(offsetMinutes / 60), 2);
          let minutes = this.pad(offsetMinutes % 60, 2);
          tz = sign + hours + minutes;

          break;
        case "utc":
          diff = 0;
          tz = "+0000";
          break;
      }

      this.diff = diff;
      return (
        moment(timeStr).add(diff, "minutes").format("YYYY-MM-DD h:mm:ssa") +
        " " +
        tz
      );
    },

    toggleFilter() {
      this.$emit("toggleFilter", !this.filtered);
    },

    toggleAmbient() {
      this.$emit("toggleAmbient", !this.ambient);
    },

    setGroundTruth(referenceValues) {
      this.groundTruthDialog = false;
      // TODO fix this issue
      // eslint-disable-next-line vue/no-mutating-props
      this.read.reference_values = referenceValues;
    },

    getTemp(read, key) {
      if (read.decoded_data.tmp_data) {
        return read.decoded_data.tmp_data[key];
      }

      return this.$get(read, `decoded_data.sensor_frames[0].${key}`);
    },
  },
};
</script>

<style src="./read-display.css" scoped></style>
