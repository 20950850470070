<template src="./patch.html"></template>
<script>
export default {
  name: "Patches",
  data() {
    return {
      patch: {},
      device_id: "",
      args: "",
      otherCommand: "",
    };
  },

  created() {
    this.device_id = this.$route.params.device_id;
    this.$store.dispatch("fetchPatch", this.device_id).then((patch) => {
      this.patch = patch;
      this.$store.commit("changeTitle", `Graftworx Patch ${patch.patch_id}`);
    });
  },

  methods: {
    createCommand(command) {
      const comm = {
        command: command,
        patch_device_id: this.patch.device_id,
        arguments: this.args,
      };

      if (command === "other") {
        comm.command = this.otherCommand;
      }

      this.$store.dispatch("createCommand", comm).then(() => {
        //TODO: This is a fairly ugly hack, but without it, the pending commands page seems to be loaded before
        // the new command has been saved; this *shouldn't* be the case, so investigate why and fix it!
        setTimeout(() => {
          this.$router.push({
            name: "PendingCommands",
            params: {
              device_id: this.patch.device_id,
            },
          });
        }, 100);
      });
    },
  },
};
</script>

<style src="./patch.css" scoped></style>
