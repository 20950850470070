<template src="./hubCommandStatus.html"></template>
<script>
export default {
  name: "HubCommandStatus",
  data() {
    return {
      loading: true,
      error: false,
      device_id: "",
      hub_id: "",
      commands: [],
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 100,
        sortBy: "ack_timestamp",
        descending: true,
      },
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [
        { text: "#", sortable: false },
        { text: "ACK timestamp", value: "ack_timestamp" },
        { text: "Status", value: "ack" },
        { text: "UUID", value: "command_uuid" },
        { text: "Command", value: "command" },
        { text: "Arguments", value: "arguments" },
        { text: "Created", value: "created_date" },
      ],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.device_id = this.$route.params.device_id;
    this.routed_from = this.$route.params.routed_from;
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("fetchHubCommandStatus", {
          device_id: this.device_id,
          params: {
            sort: this.pagination.sortBy,
            descending: this.pagination.descending,
            page: this.pagination.page,
            pageSize: this.pagination.rowsPerPage,
          },
        })
        .then((response) => {
          this.hub_id = response.hub_id;
          this.commands = response.commands;
          this.total = response.total;
          this.pages = response.pages;
          this.page = response.page;

          this.$store.commit(
            "changeTitle",
            `Graftworx Hub Command Status: Hub ${this.hub_id}`
          );
          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style src="./hubCommandStatus.css" scoped></style>
