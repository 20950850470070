<template src="./tagsEditor.html"></template>
<script>
export default {
  name: "TagsEditor",
  data() {
    return {
      loading: true,
      error: false,
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 100,
        descending: true,
      },
      tags: [],
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [
        { text: "Tag", value: "tag", align: "center" },
        { text: "", value: "", sortable: false, width: "12" },
      ],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx Tags");
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.$store
        .dispatch("getTags", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
          sort: this.pagination.sortBy,
          descending: this.pagination.descending,
        })
        .then((data) => {
          this.tags = data.tags;
          this.total = data.total;
          this.pages = data.pages;

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    deleteTag(tag, index) {
      this.$store.dispatch("deleteTag", tag).then(() => {
        this.tags.splice(index, 1);
      });
    },
  },
};
</script>

<style src="./tagsEditor.css" scoped></style>
