import { render, staticRenderFns } from "./subject.html?vue&type=template&id=442bdb37&scoped=true&"
import script from "./Subject.vue?vue&type=script&lang=js&"
export * from "./Subject.vue?vue&type=script&lang=js&"
import style0 from "./subject.css?vue&type=style&index=0&id=442bdb37&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442bdb37",
  null
  
)

export default component.exports