<template src="./userDetails.html"></template>
<script>
export default {
  name: "UserDetails",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
      valid: true,
      aclGroups: [
        { text: "User", value: "user" },
        { text: "User Admin", value: "user_admin" },
        { text: "Admin", value: "admin" },
      ],
      countryCodeMask: "+######",
      organizationRules: [],
    };
  },

  computed: {
    disabled() {
      //TODO:  Add additional "disabled" values; a basic user shouldn't be able to change their ACL group or organization
      return !(
        this.$store.state.user.acl_group === "user_admin" ||
        this.$store.state.user.acl_group === "admin" ||
        this.$store.state.user.id === this.user.id
      );
    },

    phone: {
      get() {
        if (!this.user || !this.user.phone_number) {
          return;
        }

        if (this.user.phone_number.length <= 10) {
          return this.user.phone_number;
        }

        return this.user.phone_number.slice(-10);
      },

      set(value) {
        // TODO fix this issue
        // eslint-disable-next-line vue/no-mutating-props
        this.user.phone = value;
      },
    },

    countryCode: {
      get() {
        if (!this.user || !this.user.phone_number) {
          return;
        }

        if (this.user.phone_number.length <= 10) {
          return;
        }

        return this.user.phone_number.slice(0, -10);
      },

      set(value) {
        // TODO fix this issue
        // eslint-disable-next-line vue/no-mutating-props
        this.user.countryCode = value;
      },
    },
  },
};
</script>

<style src="./userDetails.css" scoped></style>
