<template src="./ground-truth.html"></template>
<script>
import { isNil } from "lodash";

export default {
  name: "GroundTruth",
  props: {
    readID: {
      type: String,
      default: null,
    },
    reference: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
      loadingPriorReference: false,
      valid: true,
      tab: 0,
      select: "",
      items: ["m", "f"],
      numberRules: [(v) => isNil(v) || !isNaN(v) || "Must be a number"],
      referenceValues: Object.assign({}, this.reference),
    };
  },

  created() {
    this.priorReference();
  },

  methods: {
    setGroundTruth() {
      this.loading = true;

      // Adjust values based on whether this is a bench patient
      if (this.referenceValues.bench) {
        this.referenceValues.patient_id = "BENCH";
      } else {
        delete this.referenceValues.bench_phantom_id;
        delete this.referenceValues.bench_flow;
        delete this.referenceValues.bench_diam;
        delete this.referenceValues.bench_depth;
      }

      this.$store
        .dispatch("setReferenceValues", {
          readID: this.readID,
          referenceValues: this.referenceValues,
        })
        .then((ref) => {
          this.$emit("save", ref);
          this.loading = false;
        });
    },

    cancel() {
      this.$emit("cancel");
    },

    clear() {
      this.$refs.form.reset();
      this.referenceValues = {};
    },

    priorReference() {
      this.loadingPriorReference = true;

      this.$store
        .dispatch("getPriorReferenceValues", this.readID)
        .then((data) => {
          if (data.reference_values) {
            Object.entries(data.reference_values).forEach(([key, value]) => {
              if (!this.referenceValues[key]) {
                this.referenceValues[key] = value;
              }
            });
          }

          this.loadingPriorReference = false;
        });
    },
  },
};
</script>

<style src="./ground-truth.css" scoped></style>
