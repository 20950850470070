// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import "./plugins/vuetify";
import App from "./App";
import router from "./router";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import HighchartsVue from "highcharts-vue";

import lodashGet from "lodash/get";

import Alert from "@/components/Alert/Alert";
import HubSearch from "@/components/HubSearch/HubSearch";
import KitSearch from "@/components/KitSearch/KitSearch";
import GraftworxTable from "@/components/GraftworxTable/GraftworxTable";
import GraftworxHeader from "@/components/GraftworxHeader/GraftworxHeader";
import GroundTruth from "@/components/GroundTruth/GroundTruth";
import HubTable from "@/components/HubTable/HubTable";
import PatchTable from "@/components/PatchTable/PatchTable";
import PatientVitalsChart from "@/components/PatientVitalsChart/PatientVitalsChart";
import ReadDisplay from "@/components/ReadDisplay/ReadDisplay";
import IrisReadCharts from "@/components/ReadCharts/Iris/IrisReadCharts";
import TuvaluReadCharts from "@/components/ReadCharts/Tuvalu/TuvaluReadCharts";
import Tags from "@/components/Tags/Tags";
import UserDetails from "@/components/UserDetails/UserDetails";

Vue.prototype.$get = lodashGet;

Vue.prototype.moment = moment;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(Vuex);
Vue.use(HighchartsVue);

import "xterm/dist/xterm.css";

// Components
Vue.component("Alert", Alert);
Vue.component("HubSearch", HubSearch);
Vue.component("KitSearch", KitSearch);
Vue.component("GraftworxTable", GraftworxTable);
Vue.component("GraftworxHeader", GraftworxHeader);
Vue.component("GroundTruth", GroundTruth);
Vue.component("HubTable", HubTable);
Vue.component("PatchTable", PatchTable);
Vue.component("PatientVitalsChart", PatientVitalsChart);
Vue.component("ReadDisplay", ReadDisplay);
Vue.component("IrisReadCharts", IrisReadCharts);
Vue.component("TuvaluReadCharts", TuvaluReadCharts);
Vue.component("Tags", Tags);
Vue.component("UserDetails", UserDetails);

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5000/portal"
    : "/portal";

const store = new Vuex.Store({
  state: {
    user: null,
    authenticating: false,
    authenticated: false,
    accessToken: "",
    refreshToken: "",
    idToken: "",
    expiration: null,
    apiUrl: API_URL,
    environment: "",
    pageTitle: "Graftworx Portal",
    refreshTimeout: null,
    clientID: "",
    loginURL: "",
    logoutURL: "",
    redirectURL: "",
    oauthURL: "",
    sshURL: "",
  },

  mutations: {
    authenticateUser(state, auth = {}) {
      let expiration = auth.expires_in;

      if (typeof expiration === "number") {
        // Start attempting to refresh the access token 10 minutes ahead of actual expiration.
        expiration -= 600;
      }

      expiration = moment().add(expiration, "seconds");

      if (auth.access_token) {
        localStorage.setItem("accessToken", auth.access_token);
        state.accessToken = auth.access_token;
      }

      if (auth.refresh_token) {
        localStorage.setItem("refreshToken", auth.refresh_token);
        state.refreshToken = auth.refresh_token;
      }

      if (auth.id_token) {
        localStorage.setItem("idToken", auth.id_token);
        state.idToken = auth.id_token;
      }

      localStorage.setItem("expiration", expiration.format());
      state.expiration = expiration;

      state.authenticating = false;
      state.authenticated = true;

      if (state.refreshTimeout) {
        clearTimeout(state.refreshTimeout);
      }

      state.refreshTimeout = setTimeout(refreshTokens, 60000);
    },

    logout(state) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("idToken");
      localStorage.removeItem("expiration");

      state.accessToken = "";
      state.refreshToken = "";
      state.idToken = "";
      state.expiration = null;

      state.authenticated = false;

      window.location = state.logoutURL;
    },

    changeTitle(state, title) {
      state.pageTitle = title;
      document.title = title;
    },

    setEnvironment(state, environment) {
      state.environment = environment;
    },

    setAuthenticationURLs(state, userpooldata) {
      console.log("userpooldata", userpooldata);
      const IDs = {
        airforce: "70mj644qvnb1gaikq3nfcep1t6",
        ara: "2c0ihjb8drrspfpiassplobauu",
        bench: "15k4dk4cngaj877tcqj4rjj2b9",
        choa: "4tevmsdb5lg3254lmgthuggdb0",
        "choa-staging": "6mc8tk5oqh3412g0600ld9e11c",
        clinical1: "7pp54r6jbhom5leveku1skdugo", // engg-portal-user-pool-client in alio-clinical1-user-pool
        clinical2: "6gk6t70jhcfkkcbd7dq9bspauq", // engg-portal-user-pool-client in alio-clinical2-user-pool
        clintest: "1op81ui7ee4h9cvqsmq8f3gd4r",
        demo: "3h1eaj8l0rm0c5uv289aphea0e",
        dev: "31g5opbkkf8mah0bftvqpl74v2",
        dpstaging: "43o9e7eeeh13m72rve10cgu258",
        ivd: "5lrf0qchnnbbvgneh9kq8891c9",
        nhs: "fgcp9vh4ggnfu7o59umvhbpep",
        patchnet: "6g8g07pa49q9rgvu65pu194mol",
        production: "3clknvs1g2ehgg5ea1q29bljgt",
        qa: "3q177ugfjnmtkt6cejgd5h00o2",
        rad: "7o78stvplhfg7raqv9netrsa6",
        staging: "7gsp7k9k4b1d580qqik82t1ct7",
        tci: "4amcj0pnvegbm6usccurjda804",
        test: "3rck5dme4pmre03k16a74v85jo",
        preprod: "5954b8k9pg731986jkefbda25h",
        generic: "506h0gag51vcbl715fobikj4ll",
        "alio-qa": "70cj1731erued9fq84e9ivjnkf",
        prod: "2hvtr153crl5s77hrhb2cn7mla",
        waop: "5sfgu7viqsp1qlgljeraqb00t",
        devgeneric: "24pesdo0td7d2s0u28d5nlip6s",
        demo1: "4qtsqnhvgghnl2q6u4714mkens",
        "prod.uae": "7tln4op7dj6np2rc93vftjnbit",
        "prod.ksa": "27oq4jjg9n4scqf0e7vrhs454b",
        pregeneric: "7hhr44m2hvigbk5oj7fipaodia",
      };

      let clientID;
      let userPool;
      let redirectUri;
      let sshUri;
      let environment;

      // This is used for local testing
      if (process.env.NODE_ENV === "development") {
        clientID = "41crkiv6436qehs733bubnbmb2";
        userPool = "alio-dev-eng-portal";
        redirectUri = "http://localhost:8080/login";
        sshUri = "http://localhost:3000/webssh/ssh";
      } else {
        environment = userpooldata["environment"];
        // Use support portal's user pool for these environments. Use the userpool properties fetched from the backend api
        if (
          environment === "dev" ||
          environment === "qa" ||
          environment === "preprod" ||
          environment === "clinical1" ||
          environment === "clinical2" ||
          environment === "prod" ||
          environment === "waop" ||
          environment === "devgeneric" ||
          environment === "demo1" ||
          environment === "pregeneric" ||
          environment === "prod.uae" ||
          environment === "prod.ksa"
        ) {
          clientID = userpooldata["client_id"];
          userPool = userpooldata["domain"];
        } else {
          // For the legacy environments, keep using the old/original engineering portal Cognito settings
          clientID = IDs[environment];
          userPool = "graftworx";
        }
        redirectUri = `https://${environment}.graftworx.co/login`;
        sshUri = `https://${environment}.graftworx.co/webssh/ssh`;
      }

      if (!clientID) {
        return;
      }

      let region = "us-west-2";
      // uae is a different region not just environment
      if (
        environment &&
        (environment.includes("uae") || environment.includes("ksa"))
      ) {
        region = "me-south-1";
      }

      state.clientID = clientID;
      state.loginURL = `https://${userPool}.auth.${region}.amazoncognito.com/login?response_type=code&client_id=${clientID}&redirect_uri=${redirectUri}`;
      state.logoutURL = `https://${userPool}.auth.${region}.amazoncognito.com/logout?response_type=code&client_id=${clientID}&redirect_uri=${redirectUri}`;
      state.oauthURL = `https://${userPool}.auth.${region}.amazoncognito.com/oauth2/token`;
      state.redirectURL = redirectUri;
      state.sshURL = sshUri;
      console.log("state", state);
    },
  },

  actions: {
    // Session management
    authenticate(context, code) {
      if (!code) {
        return;
      }

      return axios
        .post(
          context.state.oauthURL,
          {
            grant_type: "authorization_code",
            client_id: context.state.clientID,
            code: code,
            redirect_uri: context.state.redirectURL,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          context.commit("authenticateUser", response.data);

          const redirect = localStorage.getItem("redirect");

          if (redirect) {
            localStorage.removeItem("redirect");
            // TODO: Restore query parameters also
            router.push({
              path: redirect,
            });
          } else {
            router.push({
              name: "Index",
            });
          }
        })
        .catch((err) => {
          console.error("authentication error:", err);
          return Promise.reject(err);
        });
    },

    refreshTokens(context) {
      if (!context.state.refreshToken) {
        return;
      }

      return axios
        .post(API_URL + "/api/users/refreshTokens", {
          refresh_token: context.state.refreshToken,
        })
        .then((response) => {
          context.commit("authenticateUser", response.data);
          return Promise.resolve();
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            window.location = state.loginURL;
          } else {
            console.error("refresh tokens error:", err);
          }
        });
    },

    fetchEnvironment(context) {
      // context.commit('setEnvironment', process.env.ENVIRONMENT);
      return axios
        .get(API_URL + "/api/admin/userpool")
        .then((response) => {
          context.commit("setEnvironment", response.data["environment"]);
          context.commit("setAuthenticationURLs", response.data);
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving environment:", err);
        });
    },

    restoreAuthentication(context) {
      let expiration = localStorage.getItem("expiration");

      expiration =
        !expiration || expiration.length <= 4 ? moment() : moment(expiration);

      context.state.accessToken = localStorage.getItem("accessToken");
      context.state.refreshToken = localStorage.getItem("refreshToken");
      context.state.idToken = localStorage.getItem("idToken");
      context.state.expiration = expiration;

      context.state.authenticated = !!context.state.accessToken;

      return refreshTokens();
    },

    // Reads
    fetchPatches(context, params) {
      return axios
        .get(API_URL + "/api/admin/patches", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patches:", err);
          return Promise.reject(err);
        });
    },

    fetchReads(context, { device_id, params }) {
      return axios
        .get(API_URL + "/api/patches/" + device_id + "/reads", {
          params: params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("error retrieving reads:", err);
          return Promise.reject(err);
        });
    },

    fetchPatientReads(context, { patient_id, params }) {
      return axios
        .get(API_URL + "/api/clinical/patient/" + patient_id + "/reads", {
          params: params,
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("error retrieving reads:", err);
          return Promise.reject(err);
        });
    },

    fetchRead(context, { read_id, filter, ambient }) {
      let url = `${API_URL}/api/reads?read_id=${read_id}`;
      if (filter) {
        url += `&filter=${filter}`;
      }
      if (ambient) {
        url += `&ambient=${ambient}`;
      }

      return axios
        .get(url)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("error retrieving read:", err);
          return Promise.reject(err);
        });
    },

    getPriorReferenceValues(context, read_id) {
      let url = `${API_URL}/api/reads/gt/${read_id}`;

      return axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving read:", err);
          return Promise.reject(err);
        });
    },

    setReferenceValues(context, params) {
      return axios
        .post(API_URL + "/api/reads/gt", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("set reference values error:", err);
          return Promise.reject(err);
        });
    },

    clearReferenceValues(context, params) {
      return axios
        .delete(API_URL + "/api/reads/gt", { data: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("clear reference values error:", err);
          return Promise.reject(err);
        });
    },

    setTags(context, params) {
      return axios
        .post(API_URL + "/api/reads/tags", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("set tags error:", err);
          return Promise.reject(err);
        });
    },

    batchAddTags(context, params) {
      return axios
        .post(API_URL + "/api/reads/tags/batch", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("add tags error:", err);
          return Promise.reject(err);
        });
    },

    batchReplaceTags(context, params) {
      return axios
        .post(API_URL + "/api/reads/tags/batch?replace=true", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("set tags error:", err);
          return Promise.reject(err);
        });
    },

    getTags(context, params) {
      return axios
        .get(API_URL + "/api/admin/tags", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving tags:", err);
          return Promise.reject(err);
        });
    },

    createTag(context, tag) {
      return axios
        .post(API_URL + "/api/admin/tags", {
          tag: tag,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("create tag error:", err);
          return Promise.reject(err);
        });
    },

    deleteTag(context, tag) {
      return axios
        .delete(API_URL + "/api/admin/tags", {
          data: {
            tag: tag,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("create tag error:", err);
          return Promise.reject(err);
        });
    },

    // Commands
    fetchPatch(context, device_id) {
      return axios
        .get(API_URL + "/api/patches/" + device_id)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patch:", err);
          return Promise.reject(err);
        });
    },

    fetchCommands(context, { device_id, params }) {
      return axios
        .get(API_URL + "/api/commands/" + device_id, { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving commands:", err);
          return Promise.reject(err);
        });
    },

    fetchHubCommands(context, { device_id, params }) {
      return axios
        .get(API_URL + "/api/hub_commands/" + device_id, { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving hub commands:", err);
          return Promise.reject(err);
        });
    },

    fetchCommandStatus(context, { device_id, params }) {
      return axios
        .get(API_URL + "/api/commands/" + device_id + "/status", {
          params: params,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving command status:", err);
          return Promise.reject(err);
        });
    },

    fetchHubCommandStatus(context, { device_id, params }) {
      return axios
        .get(API_URL + "/api/hub_commands/" + device_id + "/status", {
          params: params,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving hub command status:", err);
          return Promise.reject(err);
        });
    },

    createCommand(context, command) {
      axios
        .post(API_URL + "/api/commands", command)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("create command error:", err);
          return Promise.reject(err);
        });
    },

    createHubCommand(context, command) {
      axios
        .post(API_URL + "/api/hub_commands", command)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("create hub command error:", err);
          return Promise.reject(err);
        });
    },

    clearPendingCommands(context, subject_id) {
      return axios
        .delete(API_URL + "/api/commands/" + subject_id)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error clearing pending commands:", err);
          return Promise.reject(err);
        });
    },

    clearPendingHubCommands(context, subject_id) {
      return axios
        .delete(API_URL + "/api/hub_commands/" + subject_id)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error clearing pending hub commands:", err);
          return Promise.reject(err);
        });
    },

    // Hubs
    fetchHubs(context, params) {
      return axios
        .get(API_URL + "/api/hubs/", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving hubs:", err);
          return Promise.reject(err);
        });
    },

    createHub(context, hub) {
      return axios
        .post(API_URL + "/api/hubs/", hub)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("create hub error:", err);
          return Promise.reject(err);
        });
    },

    updateHub(context, hub) {
      return axios
        .put(API_URL + "/api/hubs/", hub)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("update hub error:", err);
          return Promise.reject(err);
        });
    },

    importHub(context, hubs) {
      return axios
        .post(API_URL + "/api/hubs/import", hubs)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("import hub error:", err);
          return Promise.reject(err);
        });
    },

    // Patients
    fetchPatients(context, params) {
      return axios
        .get(API_URL + "/api/admin/patients", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patients:", err);
          return Promise.reject(err);
        });
    },

    sendNotification(context, params) {
      return axios
        .post(API_URL + "/api/testing/notifications", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("send notification error:", err);
          return Promise.reject(err);
        });
    },

    getNotificationsTypes(context, params) {
      return axios
        .get(API_URL + "/api/testing/notification-types", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving notification-types:", err);
          return Promise.reject(err);
        });
    },

    createPatient(context, patient) {
      return axios
        .post(API_URL + "/api/admin/patients", patient)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("create patient error:", err);
          return Promise.reject(err);
        });
    },

    assignPatches(context, params) {
      return axios
        .post(API_URL + "/api/admin/assignPatches", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("assign patches error:", err);
          return Promise.reject(err);
        });
    },

    assignHubs(context, params) {
      return axios
        .post(API_URL + "/api/admin/assignHubs", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("assign hubs error:", err);
          return Promise.reject(err);
        });
    },

    assignKits(context, params) {
      return axios
        .post(API_URL + "/api/kits/assign", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("assign kits error:", err);
          return Promise.reject(err);
        });
    },

    fetchLightStatus(context, params) {
      return axios
        .get(API_URL + "/api/admin/light_status", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("fetch light status error:", err);
          return Promise.reject(err);
        });
    },

    updateLightStatus(context, params) {
      return axios
        .post(API_URL + "/api/admin/light_status", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("update light status error:", err);
          return Promise.reject(err);
        });
    },

    fetchPatientVitals(context, { patient_id, params }) {
      return axios
        .get(API_URL + "/api/clinical/patient/" + patient_id + "/vitals", {
          params: params,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patient vitals:", err);
          return Promise.reject(err);
        });
    },

    // Users
    fetchUsers(context, params) {
      return axios
        .get(API_URL + "/api/users/")
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving users:", err);
          return Promise.reject(err);
        });
    },

    createUser(context, params) {
      return axios
        .post(API_URL + "/api/users/", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("create user error:", err);
          return Promise.reject(err);
        });
    },

    updateUser(context, params) {
      return axios
        .put(API_URL + "/api/users/", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("update user error:", err);
          return Promise.reject(err);
        });
    },

    resetPassword(context, params) {
      if (!params) return;

      return axios
        .post(API_URL + "/api/users/resetPassword", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("reset password error:", err);
          return Promise.reject(err);
        });
    },

    fetchCurrentUser(context) {
      return axios
        .get(API_URL + "/api/users/me")
        .then((response) => {
          context.state.user = response.data;

          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving current user:", err);
          return Promise.reject(err);
        });
    },

    deleteUser(context, params) {
      return axios
        .delete(API_URL + "/api/users/", {
          data: params,
        })
        .then((response) => {
          context.state.user = response.data;

          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving current user:", err);
          return Promise.reject(err);
        });
    },

    // API Keys
    fetchKeys(context, params) {
      return axios
        .get(API_URL + "/api/keys")
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving keys:", err);
          return Promise.reject(err);
        });
    },

    createKey(context, key) {
      return axios
        .post(API_URL + "/api/keys", key)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("create key error:", err);
          return Promise.reject(err);
        });
    },

    updateKey(context, key) {
      return axios
        .put(API_URL + "/api/keys", key)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error("update key error:", err);
          return Promise.reject(err);
        });
    },

    // Search
    searchHubs(context, params) {
      return axios
        .get(API_URL + "/api/search/hubs", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving hubs:", err);
          return Promise.reject(err);
        });
    },

    searchKits(context, params) {
      return axios
        .get(API_URL + "/api/search/kits", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving kits:", err);
          return Promise.reject(err);
        });
    },

    searchPatches(context, params) {
      return axios
        .get(API_URL + "/api/search/patches", { params: params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patches:", err);
          return Promise.reject(err);
        });
    },

    // Demos

    // Dialysis
    fetchDialysisPatients(context, params) {
      return axios
        .get(API_URL + "/api/clinical/patients")
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving patients:", err);
          return Promise.reject(err);
        });
    },

    fetchDialysisAlerts(context, params) {
      return axios
        .get(API_URL + "/api/clinical/alerts")
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("error retrieving alerts:", err);
          return Promise.reject(err);
        });
    },

    updateAlertStatus(context, params) {
      if (!params.alert) {
        return;
      }

      return axios
        .put(API_URL + "/api/clinical/alerts", params)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error("update alert status error:", err);
          return Promise.reject(err);
        });
    },
  },
});

function refreshTokens() {
  if (store.state.expiration.isAfter(moment())) {
    store.state.refreshTimeout = setTimeout(refreshTokens, 60000);
    return Promise.resolve();
  }

  return store.dispatch("refreshTokens");
}

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status === 401) {
      const redirect = window.location.pathname;

      if (redirect !== "/login") {
        localStorage.setItem("redirect", redirect);
      }

      window.location = store.state.loginURL;
    }
    // if(err.response?.status === 403) {
    // TODO: When an authenticated user attempts an action they aren't authorized to commit, redirect to an authorized module
    // }

    return Promise.reject(err);
  }
);

axios.interceptors.request.use(
  (config) => {
    // Only add the access token to requests to the API.
    if (config.url.indexOf(API_URL) !== -1) {
      config.headers.AccessToken = store.state.accessToken;
    }

    // Convert a json object to a url encoded string for specified content type
    if (
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      config.data = Object.entries(config.data)
        .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join("&");
    }

    return config;
  },
  (err) => {
    // Do something with request error
    return Promise.reject(err);
  }
);

// Global filters
Vue.filter("capitalize", (value) => {
  if (!value) return;
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("upperCase", (value) => {
  if (!value) return;
  return value.toString().toUpperCase();
});

/**
 * Return the median value for an array of numbers or the values of an object,
 * where all the values are numbers.
 * @param values
 * @returns {number}
 */
Vue.filter("median", (values) => {
  let v;

  if (Array.isArray(values)) {
    // Clone the array to avoid mutating the referenced object,
    // which can trigger vue's update lifecycle
    v = values.slice(0);
  } else if (typeof values === "object") {
    if (values.processed && Array.isArray(values.processed)) {
      v = values.processed.slice(0);
    } else if (values.data && Array.isArray(values.data)) {
      v = values.data.slice(0);
    } else {
      v = Object.values(values);
    }
  } else {
    return;
  }

  // Make sure all the values are numbers.  Return if any value is not a number.
  if (
    v.some((n) => {
      return isNaN(parseFloat(n)) || !isFinite(n);
    })
  ) {
    return;
  }

  if (v.length === 0) {
    return;
  } else if (v.length === 1) {
    return v[0];
  }

  v.sort((a, b) => a - b);

  let half = Math.floor(v.length / 2);

  if (!v.length % 2) {
    return v[half].toFixed(2);
  } else {
    return ((v[half - 1] + v[half]) / 2.0).toFixed(2);
  }
});

Vue.filter("average", (values) => {
  let v;

  if (Array.isArray(values)) {
    // Clone the array to avoid mutating the referenced object,
    // which can trigger vue's update lifecycle
    v = values.slice(0);
  } else if (typeof values === "object") {
    v = Object.values(values);
  } else {
    return;
  }

  // Make sure all the values are numbers.  Return if any value is not a number.
  if (
    v.some((n) => {
      return isNaN(parseFloat(n)) || !isFinite(n);
    })
  ) {
    return;
  }

  if (v.length === 0) {
    return;
  } else if (v.length === 1) {
    return v[0];
  }

  let total = v.reduce((a, b) => a + b);
  return (total / v.length).toFixed(2);
});

Vue.filter("sigFigs", (value) => {
  const b = Number.parseFloat(value);

  if (isNaN(b)) return;

  return b.toFixed(2);
});

Vue.filter("hrMUSIC", (values) => {
  if (!Array.isArray(values)) return;
  let last_value = values[values.length - 1];
  return last_value.toFixed(2);
});

Vue.filter("abs", (value) => {
  const b = Number.parseFloat(value);

  if (isNaN(b)) return;

  const abs = Math.abs(b);

  if (b !== abs) {
    console.error("Negative temp value found:", b);
  }

  return abs;
});

Vue.filter("abs2", (value) => {
  const b = Number.parseFloat(value);

  if (isNaN(b)) return;

  const abs = Math.abs(b);

  if (b !== abs) {
    console.error("Negative temp value found:", b);
  }

  return abs.toFixed(2);
});

Vue.filter("avgResult", (values) => {
  if (!Array.isArray(values)) {
    return;
  }

  const avg = values.find((e) => e.source_descriptor === "average");

  if (!avg) {
    return;
  }

  if (typeof avg.value !== "number") return;

  return avg.value.toFixed(2);
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
