<template src="./dialysis.html"></template>
<script>
import { defaults, remove, get } from "lodash";
import moment from "moment";

export default {
  name: "Dialysis",

  data() {
    return {
      loading: true,
      today: moment().format("dddd, D MMMM YYYY"),
      title: "",
      viewAlerts: true,
      btnLabel: "View Patients",
      dialog: false,
      modalLabel: "",
      modalColor: {},
      alerts: [],
      patients: [],
      scheduledPatients: [],
      alertColors: {
        primary: "#b31010",
        secondary: "#e12f32",
      },
      patientColors: {
        primary: "#005c95",
        secondary: "#007bb8",
      },
      subjectData: {},
      subjectIndex: -1,
      subjectSource: "",
      charts: [],
      mockCharts: {},
      patientHeaders: ["patient_id", "", "status", "action"],
      modalHeaders: ["patient_id", "", "", "action"],
      pollAlerts: null,
      mockOffsets: {},
      initialOffsets: {},
    };
  },

  computed: {
    colorStyle() {
      return {
        color: this.modalColor.secondary,
      };
    },

    flow() {
      if (!this.charts[0] || !get(this.charts[0], "series[0].data")) {
        return;
      }

      const last = this.charts[0].series[0].data.length - 1;
      const flow = this.charts[0].series[0].data[last][1];
      return `${flow.toFixed(1)} ml/min`;
    },

    hct() {
      if (!this.charts[1] || !get(this.charts[1], "series[0].data")) {
        return;
      }

      const last = this.charts[1].series[0].data.length - 1;
      const hct = this.charts[1].series[0].data[last][1];
      return `${hct.toFixed(1)}%`;
    },
  },

  created() {
    this.mockOffsets = {
      m1: this.randomTimestamp(),
      m2: this.randomTimestamp(),
    };

    this.mockCharts = {
      M1: [
        this.hcOptions({
          title: {
            text: "Flow",
          },
          yAxis: {
            title: {
              text: "ml/min",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 599,
              },
              {
                color: "#ffffc9",
                from: 2001,
                to: 5000,
              },
            ],
            min: 250,
            max: 2500,
          },
          series: [
            {
              type: "line",
              name: "ml/min",
              data: this.calculateTime([
                1088.05945153, 1083.98264585, 1080.65888039, 1078.01979109,
                1075.99701388, 1074.52218467, 1073.5269394, 1072.94291399,
                1072.70174438, 1072.73506648, 1072.97451623, 1073.35172954,
                1073.79834236, 1073.39419135, 1072.35709453, 1071.80856947,
                1072.98816053, 1073.99846213, 1073.12375808, 1073.33230219,
                1073.07479913, 1072.65452089, 1072.53206361, 1072.517174,
                1071.5946209, 1070.36438677, 1069.73919747, 1070.58003443,
                1070.57123884, 1067.87879145, 1067.68089461, 1068.07149601,
                1064.63846485, 1064.39579101, 1065.18263976, 1063.80187529,
                1063.74029443, 1064.31255783, 1065.76521272, 1065.91074887,
                1068.08957937, 1069.22572452, 1067.63120516, 1069.45100395,
                1072.15576062, 1072.34355986, 1073.27813495, 1073.08311665,
                1072.97256504, 1075.22948609, 1075.30270619, 1074.49028276,
                1072.71154024, 1070.97196665, 1071.45666337, 1068.95494468,
                1066.50479298, 1064.79870659, 1062.25723945, 1060.38642858,
                1061.06062807, 1061.37624325, 1061.15570477, 1060.84073214,
                1060.64214183, 1061.42439667, 1062.6766195, 1064.57424542,
                1067.10704146, 1067.67641376, 1068.91906438, 1068.86382671,
                1072.29572263, 1073.099662, 1071.90987789, 1070.59292225,
                1071.20454956, 1073.05701658, 1073.89279762, 1070.17264924,
                1071.19461114, 1069.39862668, 1069.76858523, 1070.26086183,
                1070.39666623, 1070.08955166, 1068.79378401, 1068.39137311,
                1067.61818314, 1067.85672512, 1067.4679158, 1067.6110351,
                1066.82319647, 1066.12276565, 1066.88598911, 1067.43672059,
                1067.80563635, 1066.68739962, 1066.34081866, 1066.23060009,
                1069.52397984, 1072.35049995, 1071.8395327, 1070.39441746,
                1072.71695044, 1072.66919408, 1070.99485082, 1070.05834166,
                1069.52862553, 1068.8020776, 1069.46219496, 1070.18668359,
                1068.99253547, 1072.27791689, 1073.89837493, 1074.58796078,
                1074.27161895, 1075.33127093, 1075.92972955, 1073.62159729,
                1071.6829945, 1071.29585264, 1070.00615628, 1069.97896508,
                1071.56277711, 1070.78200081, 1069.52407325, 1069.529048,
                1069.14712466, 1069.96265644, 1068.29058798, 1069.85868538,
                1069.19057114, 1070.02267242, 1069.50116283, 1069.9810087,
                1070.64413428, 1072.00146253, 1068.80243449, 1068.16329157,
                1066.5343827, 1066.30014628, 1067.00759426, 1065.31120944,
                1065.46864366, 1064.84842787, 1065.68344204, 1067.71548348,
                1069.35799211, 1068.88817829, 1067.49916262, 1067.31124406,
                1068.0283136, 1069.78459165, 1069.40261195, 1070.15472193,
                1069.9337701, 1069.21629487, 1066.30716308, 1067.36674874,
                1066.04623209, 1066.23633905, 1066.83320946, 1067.27926809,
                1067.23165874, 1067.77518392, 1065.155002, 1062.2710843,
                1062.34134985, 1061.61392671, 1061.46016464, 1061.31221869,
                1061.77213122, 1059.11240458, 1056.26771395, 1056.08786555,
                1054.83544398, 1053.15607255, 1051.89060151, 1047.13759409,
                1044.95041846, 1042.59826918, 1039.67281764, 1039.16694109,
                1037.31270112, 1034.82224335, 1029.29946444, 1025.48905881,
                1020.92506788, 1014.57258611, 1009.61327863, 1007.42107251,
                1004.27924659, 1000.48723176, 994.00478262, 989.06872847,
                982.81057569, 974.95134505, 968.49505505, 961.39212929,
                951.06969938, 941.81501323, 931.13093727, 918.5142054,
                910.12304642, 898.59174874, 883.89459637, 868.44589917,
                853.47050833, 835.4616585, 817.76027113, 802.41037418,
                784.92825199, 767.0165908, 748.89075522, 730.63117744,
                712.31828964, 694.03252403, 675.85431278, 657.8640881,
                640.14228217, 622.7693272, 605.82565536, 589.39169885,
                573.54788986,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:,.1f} ml/min",
          },
        }),
        this.hcOptions({
          title: {
            text: "Hematocrit",
          },
          yAxis: {
            title: {
              text: "Percent",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 32,
              },
              {
                color: "#ffffc9",
                from: 37,
                to: 100,
              },
            ],
            min: 25,
            max: 40,
          },
          series: [
            {
              type: "line",
              name: "Percent",
              data: this.calculateTime([
                34.07681865, 33.98911264, 33.99605286, 34.07408951, 34.19151979,
                34.31681254, 34.42414506, 34.49653168, 34.52692367, 34.51765999,
                34.47864859, 34.42465763, 34.37209642, 34.33566544, 34.42373003,
                34.46874539, 34.55881543, 34.4223382, 34.40842857, 34.1631308,
                34.02681024, 34.14647165, 34.10768928, 34.10389198, 34.11152412,
                34.25021146, 34.41119319, 34.65843953, 34.79199042, 34.88041752,
                34.97908301, 35.01665313, 34.99915096, 34.94896016, 34.88992953,
                34.694403, 34.59690166, 34.62410307, 34.74881087, 34.6140639,
                34.77619238, 34.7899952, 34.75891869, 34.55677175, 34.42055638,
                34.14824948, 34.02508703, 34.24267345, 34.44565155, 34.78836961,
                34.78382402, 35.06813152, 34.97958966, 34.98286056, 34.88711564,
                34.72320425, 34.62719054, 34.36173257, 34.12181981, 33.8556481,
                33.829604, 33.81793583, 33.95374454, 34.00179318, 34.22187276,
                34.22930622, 34.21526819, 34.26997528, 34.30861295, 34.4385651,
                34.60907849, 34.74041148, 34.84973433, 34.74220932, 34.73082093,
                34.66960237, 34.60899755, 34.89609986, 34.76175743, 34.76170347,
                34.72081029, 34.52308036, 34.46793689, 34.44459609, 34.43757881,
                34.45245111, 34.41999502, 34.50532533, 34.74610479, 34.79233588,
                34.69890947, 34.73199162, 34.77516547, 34.77885923, 34.69633932,
                34.52208658, 34.52135857, 34.45249282, 34.51366453, 34.5521483,
                34.56913131, 34.45244429, 34.40359551, 34.36708976, 34.43467025,
                34.62601468, 34.51401337, 34.57808421, 34.55956602, 34.63162443,
                34.62904619, 34.61214139, 34.57487841, 34.58455712, 34.50549491,
                34.48017668, 34.39821552, 34.051362, 34.08371709, 34.19420393,
                34.26971436, 34.38192328, 34.58232678, 34.71388392, 34.81628835,
                34.97786752, 34.90598305, 34.94199079, 34.78935125, 34.46415387,
                34.30023221, 34.16626551, 33.80319594, 33.82760124, 33.84566622,
                34.04121946, 34.20829627, 34.29961382, 34.27682532, 34.29706293,
                34.1045512, 33.99362826, 34.23299001, 34.11104905, 34.23540918,
                34.28033321, 34.34847367, 34.3208167, 34.37193571, 34.45182602,
                34.50299679, 34.52013153, 34.28117818, 34.21536419, 34.04165012,
                33.89862499, 34.0568326, 34.15574999, 34.22294093, 34.18540122,
                34.0900967, 34.08359535, 34.09671517, 34.20132163, 34.17113202,
                34.08446028, 33.99689072, 34.12376987, 34.29867824, 34.33711247,
                34.46064058, 34.67068616, 34.7798796, 34.661584, 34.32665357,
                34.07323287, 33.94416683, 33.84496664, 33.80943799, 33.8765826,
                33.87255306, 33.83408398, 33.97973557, 34.05009173, 34.32185649,
                34.52554987, 34.47992968, 34.4904736, 34.29817114, 34.28284832,
                34.38755887, 34.44610871, 34.40686368, 34.66706187, 34.55493594,
                34.50801275, 34.38830846, 34.31196732, 34.09760389, 34.09090756,
                34.02497753, 34.06001485, 34.18363417, 34.13178991, 34.19776406,
                34.27441606, 34.30099911, 34.37019725, 34.25535849, 34.09666183,
                34.04781533, 33.97450866, 33.99779783, 34.04587566, 34.10244304,
                34.15332495, 34.19067272, 34.21575774, 34.23957109, 34.28044314,
                34.3578977, 34.48195497, 34.63709755, 34.76011398, 34.71103402,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:.1f}%",
          },
        }),
      ],
      M2: [
        this.hcOptions({
          title: {
            text: "Flow",
          },
          yAxis: {
            title: {
              text: "ml/min",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 599,
              },
              {
                color: "#ffffc9",
                from: 2001,
                to: 5000,
              },
            ],
            min: 250,
            max: 2500,
          },
          series: [
            {
              type: "line",
              name: "ml/min",
              data: this.calculateTime([
                1087.55249627, 1086.68473668, 1085.8058249, 1084.93016758,
                1084.07217137, 1083.24624293, 1082.46678892, 1081.74821597,
                1081.10493076, 1080.55133992, 1080.10185012, 1079.77086801,
                1079.57280024, 1078.65501144, 1081.96564088, 1082.7992625,
                1084.15194815, 1083.79696816, 1082.29364587, 1081.78352435,
                1082.1516919, 1081.63242931, 1080.8303666, 1082.43911896,
                1084.01529949, 1083.63619752, 1083.04730679, 1081.58575235,
                1080.65385345, 1080.41332889, 1082.35817394, 1083.04204408,
                1081.80241376, 1079.77883089, 1077.4572659, 1077.04517068,
                1077.6077384, 1078.15360203, 1081.12639584, 1081.06242233,
                1081.8278286, 1083.7102477, 1083.14581565, 1084.32503121,
                1084.47466664, 1086.19455264, 1087.85092261, 1089.12480971,
                1088.81499243, 1088.78913472, 1088.43787313, 1087.64475304,
                1087.13256164, 1084.67899636, 1083.98244987, 1081.35926377,
                1079.07047012, 1076.86876961, 1076.68219498, 1076.34109309,
                1076.44811713, 1077.13878666, 1079.58204016, 1079.72977275,
                1080.14809068, 1081.87357767, 1081.4189223, 1081.40290237,
                1080.16934782, 1081.85907699, 1083.07867479, 1083.94117522,
                1084.17865109, 1083.65161727, 1083.01906466, 1082.46989087,
                1081.56301312, 1080.06115171, 1081.42187493, 1083.16293103,
                1084.34939999, 1084.72659897, 1086.4238034, 1086.75904797,
                1086.19124678, 1088.15134919, 1088.33564271, 1087.01046679,
                1087.47931465, 1088.48783972, 1086.96292873, 1084.1666271,
                1082.06617065, 1083.0510948, 1081.70166554, 1079.66317175,
                1077.2777651, 1077.44791332, 1078.36606068, 1076.48665617,
                1076.52794549, 1077.52905484, 1076.24173494, 1080.17082041,
                1080.3634975, 1080.02181483, 1083.02903016, 1084.7672924,
                1085.14913031, 1088.30055162, 1087.37759877, 1088.61851948,
                1089.33688675, 1088.50949825, 1088.28488691, 1088.037122,
                1089.21006651, 1089.31662908, 1086.31336012, 1085.65678918,
                1085.89130649, 1087.83726317, 1086.48270639, 1084.65966927,
                1084.58886342, 1083.85517782, 1083.11412578, 1086.00518227,
                1082.32811163, 1083.40690338, 1085.16131946, 1085.44026007,
                1085.21807447, 1085.20455885, 1085.07355936, 1086.02136949,
                1086.92596481, 1088.50620758, 1091.6784571, 1091.25888968,
                1092.11758528, 1091.67147743, 1090.08951638, 1091.82576071,
                1090.43838032, 1091.87495078, 1088.96369085, 1088.68718707,
                1088.3998496, 1087.3687877, 1088.73334272, 1085.34911571,
                1083.85517546, 1084.49440225, 1083.23531404, 1081.48688639,
                1079.99714034, 1079.33008368, 1078.75853396, 1077.70788419,
                1078.26677619, 1076.97628384, 1076.02074006, 1073.95054108,
                1075.48454084, 1074.18361548, 1072.07900811, 1071.08624786,
                1069.99392008, 1071.26266951, 1070.81272205, 1072.29899867,
                1072.91428625, 1072.35602524, 1072.64982335, 1071.24027125,
                1074.0025327, 1071.99377597, 1069.56642726, 1070.04781349,
                1071.95460692, 1072.17465882, 1070.92089956, 1069.2482087,
                1067.09824812, 1064.5801332, 1060.46323667, 1058.49928022,
                1055.3525478, 1049.40675173, 1043.7215833, 1037.45000448,
                1032.3973903, 1026.57089208, 1017.87589351, 1009.8546479,
                999.68178978, 989.4794414, 979.03202624, 966.4615443,
                955.25618976, 941.62875596, 928.79036179, 916.98358038,
                905.79173462, 888.60694152, 872.0549929, 854.62146394,
                837.02154456, 818.9675601, 801.1896456, 783.39590127,
                764.06485264, 744.99873755, 725.98913749, 707.15282086,
                688.60655608, 670.46711157, 652.85125575, 635.87575702,
                619.65738381, 604.31290453, 589.95908759, 576.71270142,
                564.69051442,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:,.1f} ml/min",
          },
        }),
        this.hcOptions({
          title: {
            text: "Hematocrit",
          },
          yAxis: {
            title: {
              text: "Percent",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 32,
              },
              {
                color: "#ffffc9",
                from: 37,
                to: 100,
              },
            ],
            min: 25,
            max: 40,
          },
          series: [
            {
              type: "line",
              name: "Percent",
              data: this.calculateTime([
                32.5568914, 34.21034233, 34.83851895, 34.8203903, 34.4649773,
                34.00576318, 33.60120909, 33.34032968, 33.25228316, 33.31893061,
                33.48931917, 33.69504381, 33.86544228, 33.94157797, 33.7596935,
                33.3665296, 33.19185674, 33.06188078, 33.03246572, 33.08415794,
                33.54653167, 33.83483977, 34.10131877, 34.11358534, 34.11629014,
                33.88498231, 33.56737613, 33.23736148, 33.13641595, 32.91235294,
                32.52681392, 32.58580441, 32.74476753, 33.27876777, 33.54821463,
                33.879927, 33.99201865, 34.03467087, 33.82734552, 33.83579138,
                33.86252628, 33.74024859, 33.50224738, 33.54292521, 33.55315108,
                33.70833055, 34.00849118, 34.10415426, 34.18032045, 33.93884663,
                34.02678624, 33.89634293, 33.76007429, 33.51901047, 33.44576659,
                33.73938877, 33.72928555, 33.92972015, 33.98317472, 33.90494399,
                33.70514146, 33.65593425, 33.59575227, 33.20003595, 32.88168726,
                32.71159977, 32.85682501, 32.9997517, 32.87080634, 32.83288138,
                32.75718811, 32.91654413, 32.97073419, 32.96543617, 33.32563309,
                33.44158821, 33.7027945, 33.59863094, 33.81524142, 33.95202554,
                33.7462433, 33.55123396, 33.55501982, 33.37683345, 33.39016473,
                33.24998542, 33.31172845, 33.57314131, 33.8543268, 33.91620503,
                34.02620058, 34.02658024, 33.88631988, 33.71049818, 33.33272353,
                33.04198268, 32.60212506, 32.47616431, 32.64043072, 32.81991777,
                33.11500862, 33.69875944, 34.27744536, 34.33621812, 34.39103838,
                34.18143007, 33.80119578, 33.35411695, 33.30090697, 33.07655409,
                33.04485236, 32.95066197, 33.52211577, 33.92857235, 34.0783754,
                34.03586252, 34.0727003, 33.7507362, 33.57339014, 33.17346539,
                33.00024707, 33.05143028, 33.14182554, 33.15877074, 32.97018522,
                33.18538975, 33.1229874, 32.92315267, 32.63535509, 32.58020015,
                32.76394313, 32.77790835, 32.77406509, 32.91036451, 33.17850483,
                33.58403423, 33.82148716, 33.82504829, 34.04811021, 33.97224838,
                33.99558106, 33.70135502, 33.61583611, 32.98389341, 32.79197172,
                32.59474362, 32.60087989, 32.4517266, 32.64355022, 32.76763881,
                32.67809411, 33.2425795, 33.55115465, 33.85387495, 34.07859407,
                34.13550549, 34.26411345, 34.20610049, 33.68857322, 33.41840134,
                33.10888957, 33.03121975, 32.86238428, 32.92892301, 33.25092318,
                33.35817905, 33.57500769, 33.94306456, 34.51992989, 34.5511658,
                34.34047169, 34.22348851, 33.96884872, 33.66918325, 33.51760071,
                33.38868385, 33.34538683, 33.52022217, 33.59544472, 33.64761435,
                33.7878372, 33.83234915, 33.92475349, 33.8529591, 33.3616035,
                33.01377322, 32.8628689, 32.82688699, 32.64625073, 32.72949633,
                33.09486002, 33.69511351, 33.79062103, 34.05135222, 34.02250329,
                33.93257326, 33.54062079, 33.38972278, 33.0370769, 32.70247124,
                32.54364678, 32.36596124, 32.65241469, 33.00696718, 33.59507998,
                33.70744292, 33.47222269, 33.47629976, 33.44529609, 33.39247141,
                33.33551186, 33.29475445, 33.29058038, 33.33981567, 33.45097692,
                33.61820055, 33.81369356, 33.97854407, 34.01172951, 33.75716093,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:.1f}%",
          },
        }),
      ],
      default: [
        this.hcOptions({
          title: {
            text: "Flow",
          },
          yAxis: {
            title: {
              text: "ml/min",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 599,
              },
              {
                color: "#ffffc9",
                from: 2001,
                to: 5000,
              },
            ],
            min: 250,
            max: 2500,
          },
          series: [
            {
              type: "line",
              name: "ml/min",
              data: this.calculateTime([
                1130.20058095, 1128.37755628, 1134.82223256, 1139.68864677,
                1143.1308359, 1145.30283693, 1146.35868684, 1146.45242261,
                1145.73808122, 1144.36969965, 1142.5013149, 1140.28696393,
                1137.88068374, 1134.7915045, 1132.34158499, 1133.33201296,
                1134.9941059, 1135.69588146, 1137.31212214, 1136.07676221,
                1134.53957031, 1134.49581233, 1135.95975839, 1136.91628712,
                1137.33166175, 1139.29916513, 1138.17295756, 1138.10887203,
                1138.23553154, 1139.3691127, 1137.51800403, 1137.26102953,
                1135.01493842, 1133.03939167, 1132.18972033, 1132.67537533,
                1134.6361961, 1134.45759803, 1138.5207511, 1141.65280569,
                1141.95984098, 1142.46259738, 1142.03484904, 1140.6804963,
                1141.85448736, 1143.53515593, 1144.13616424, 1143.17041842,
                1142.87067513, 1142.09864918, 1141.03644669, 1140.54306956,
                1139.09383836, 1137.84130051, 1136.04536394, 1136.16661039,
                1136.54014223, 1136.17419193, 1135.32586856, 1134.89505842,
                1136.61361565, 1136.65439101, 1138.7110318, 1138.43759491,
                1139.13293983, 1140.49211544, 1140.07235324, 1139.23105714,
                1141.8335653, 1140.12768988, 1141.41996293, 1139.4309202,
                1138.87643741, 1137.93164218, 1136.88221619, 1136.46278528,
                1133.73179429, 1133.30287618, 1133.55589773, 1134.26603005,
                1133.6096341, 1133.25192532, 1131.9551496, 1131.56878133,
                1134.9741865, 1134.65703435, 1136.64889179, 1138.66551635,
                1139.6250658, 1135.85341948, 1136.26915098, 1135.00309444,
                1136.44580337, 1137.10848403, 1138.71725487, 1137.22490687,
                1137.34954246, 1138.95797175, 1140.53025628, 1141.63567031,
                1141.05263526, 1142.2973103, 1141.76167143, 1141.66070658,
                1141.87627378, 1143.39184869, 1140.35720496, 1138.80976267,
                1140.52601343, 1139.80653276, 1139.48772643, 1138.74235,
                1135.19247157, 1131.98927946, 1136.49948405, 1135.08642981,
                1135.78088902, 1136.51974484, 1135.52485951, 1135.35479516,
                1136.23879733, 1137.34642678, 1138.6119227, 1138.01538151,
                1136.7471009, 1136.09888721, 1135.15972417, 1136.41760315,
                1137.90673696, 1137.71376807, 1136.40673639, 1137.59642816,
                1141.63534917, 1142.03599008, 1142.52792739, 1141.46346448,
                1141.45215372, 1142.27671509, 1144.87135464, 1142.22314744,
                1140.29590814, 1140.26892461, 1139.26832539, 1138.66061084,
                1137.51044019, 1136.22826941, 1135.33580146, 1133.24388413,
                1133.37362698, 1133.06004063, 1134.17316038, 1135.00980689,
                1135.13116932, 1135.44733302, 1135.92333288, 1137.82823881,
                1140.51924841, 1139.12837298, 1137.40876646, 1136.64188343,
                1138.32595108, 1136.84140996, 1135.83635481, 1136.1811774,
                1134.22510842, 1137.26197897, 1136.41378965, 1133.86373692,
                1134.74732094, 1136.38462722, 1136.63900573, 1137.83513961,
                1138.16648536, 1139.5310355, 1140.44695571, 1139.17171429,
                1141.01020765, 1143.40582473, 1142.51766054, 1142.87983447,
                1142.12929794, 1140.82108022, 1139.34247819, 1139.31620491,
                1137.93157901, 1134.95687401, 1135.23957597, 1135.66994919,
                1132.1821335, 1134.21788795, 1131.04751297, 1130.22743903,
                1132.10058236, 1130.45101262, 1129.59392281, 1130.63036601,
                1130.95332874, 1132.00275348, 1129.88217509, 1131.11302843,
                1132.20255348, 1132.63177504, 1131.92684335, 1130.73973635,
                1131.4080495, 1131.49624008, 1132.04994232, 1131.77629489,
                1131.77216344, 1131.90141516, 1134.15005129, 1134.19393258,
                1133.99650415, 1134.65475584, 1135.31155247, 1135.94313832,
                1136.52575767, 1137.03565479, 1137.44907396, 1137.74225945,
                1137.89145555, 1137.87290653, 1137.66285666, 1137.23755023,
                1136.5732315,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:,.1f} ml/min",
          },
        }),
        this.hcOptions({
          title: {
            text: "Hematocrit",
          },
          yAxis: {
            title: {
              text: "Percent",
            },
            plotBands: [
              {
                color: "#ffcdc4",
                from: 0,
                to: 32,
              },
              {
                color: "#ffffc9",
                from: 37,
                to: 100,
              },
            ],
            min: 25,
            max: 40,
          },
          series: [
            {
              type: "line",
              name: "Percent",
              data: this.calculateTime([
                34.85255942, 34.74293986, 34.88398063, 35.08530588, 35.24500838,
                35.32305967, 35.31950789, 35.25707115, 35.16773473, 35.08295976,
                35.02711183, 35.01371717, 35.04415467, 35.10839167, 35.34825298,
                35.27830192, 35.19032498, 35.07660861, 34.96623723, 34.8922638,
                34.8977498, 34.81603467, 34.7716811, 34.7145404, 34.95143616,
                34.99844932, 35.19442466, 35.2942926, 35.31961258, 35.4818993,
                35.31510749, 35.22220332, 35.22016467, 35.18439364, 35.32878164,
                35.49522507, 35.61433162, 35.4896003, 35.4876657, 35.36411252,
                35.27321729, 35.16625299, 35.11380118, 34.95559826, 34.87082235,
                35.04591024, 35.23099062, 35.30135186, 35.40494211, 35.32559374,
                35.29623032, 35.15561023, 35.01642062, 34.96823494, 34.94259117,
                35.05801642, 35.20196301, 35.28547899, 35.37200317, 35.38496858,
                35.38639289, 35.41210926, 35.2844914, 34.95355495, 34.97078233,
                35.04926499, 35.21852412, 35.34033341, 35.55234234, 35.58691075,
                35.56084556, 35.62107912, 35.62913702, 35.45120218, 35.41097327,
                35.24338393, 35.19202195, 35.22942967, 35.12596163, 35.2769328,
                35.29149587, 35.32695152, 35.34287617, 35.24108764, 35.15137405,
                35.1153457, 35.09849145, 35.32518156, 35.41107499, 35.44013195,
                35.45046773, 35.60472274, 35.74352479, 35.72511653, 35.679413,
                35.60891899, 35.40056087, 35.21794759, 35.17161261, 35.13355896,
                35.23514952, 35.26017914, 35.24324336, 35.37081168, 35.36659771,
                35.42410214, 35.37265707, 35.27159485, 35.16691811, 35.08058145,
                35.08268658, 35.08492084, 35.12403796, 35.29763943, 35.54673052,
                35.69116514, 35.62014, 35.61418741, 35.452172, 35.20694768,
                34.97144333, 34.94616406, 34.86759539, 34.87090495, 34.91420786,
                34.94913477, 35.04102403, 35.27789054, 35.29273557, 35.23191071,
                35.20506097, 35.11673049, 35.04286521, 34.94350181, 34.90652956,
                35.01587959, 35.1363535, 35.34850785, 35.32355218, 35.36698673,
                35.36773363, 35.23190688, 35.19376966, 35.20738425, 35.18265706,
                35.15148134, 35.05477355, 34.94480581, 34.82682748, 34.80017443,
                34.82722547, 34.72491629, 34.68896926, 34.65775475, 34.67653472,
                34.76314194, 35.07782875, 35.29415939, 35.50961166, 35.62098661,
                35.69822184, 35.72233768, 35.70879866, 35.65224826, 35.35359347,
                35.25385232, 35.18946062, 35.15258458, 35.15588986, 35.09601346,
                35.1430345, 35.24264847, 35.21793082, 35.16030063, 35.0407344,
                35.0087631, 35.00757034, 34.95156965, 34.95950237, 34.96320559,
                35.03834727, 35.11375243, 35.26319858, 35.21222177, 35.24536116,
                35.25580312, 35.05290046, 34.89643975, 34.91767502, 34.95685347,
                34.99143082, 35.05196063, 35.19249241, 35.25367062, 35.31387281,
                35.2480003, 35.41521177, 35.46389918, 35.44320075, 35.38639245,
                35.16476904, 35.05817711, 35.0525119, 35.16751265, 35.22475291,
                35.26499676, 35.30436051, 35.34147637, 35.32898259, 35.25911725,
                35.1387359, 34.99050654, 34.85086934, 34.763919, 34.77036789,
                34.89074785, 35.1020087, 35.30667153, 35.29369467, 34.69021036,
              ]),
            },
          ],
          tooltip: {
            pointFormat: "Value: {point.y:.1f}%",
          },
        }),
      ],
    };

    Promise.all([
      this.$store.dispatch("fetchDialysisAlerts"),
      this.$store.dispatch("fetchDialysisPatients"),
    ]).then(([alertData, patientData]) => {
      this.scheduledPatients = remove(alertData.alerts, (o) => {
        this.initialOffsets[o.patient_id] = o.read_timestamp;
        this.alterTimestamp(o);

        const scheduled = o.status === "scheduled";

        if (scheduled) {
          o.source = "alerts";
        }

        return scheduled;
      });
      this.alerts = alertData.alerts;
      this.patients = patientData.patients;

      this.loading = false;

      this.pollAlerts = setTimeout(this.refreshAlerts, 5000);
    });
  },

  destroyed() {
    clearTimeout(this.pollAlerts);
  },

  methods: {
    refreshAlerts() {
      this.$store
        .dispatch("fetchDialysisAlerts")
        .then((alertData) => {
          this.scheduledPatients = remove(alertData.alerts, (o) => {
            this.alterTimestamp(o);

            const scheduled = o.status === "scheduled";
            if (scheduled) {
              o.source = "alerts";
            }
            return scheduled;
          });
          this.alerts = alertData.alerts;
          this.pollAlerts = setTimeout(this.refreshAlerts, 5000);
        })
        .catch((err) => {
          this.pollAlerts = setTimeout(this.refreshAlerts, 5000);
        });
    },

    upperCase(str) {
      return str.toUpperCase();
    },

    toggleAlerts() {
      this.viewAlerts = !this.viewAlerts;
      this.btnLabel = this.viewAlerts ? "View Patients" : "View Alerts";
    },

    viewAlert(index) {
      if (this.alerts[index].status === "unread") {
        this.alerts[index].status = "read";
        // TODO: Add semi-discrete loading indicator
        this.$store.dispatch("updateAlertStatus", {
          alert: this.alerts[index]._id.$oid,
          status: "read",
        });
      }

      this.modalLabel = "Alerts";
      this.modalColor = this.alertColors;
      this.subjectData = this.alerts[index];
      this.subjectIndex = index;
      this.subjectSource = "alerts";
      this.charts =
        this.mockCharts[this.subjectData.patient_id] ||
        this.mockCharts["default"];
      this.dialog = true;
    },

    viewPatient(index) {
      this.modalLabel = "Patients";
      this.modalColor = this.patientColors;
      this.subjectData = this.patients[index];
      this.subjectIndex = index;
      this.subjectSource = "patients";

      const patientID = this.subjectData.patient_id;

      this.charts = !!this.mockCharts[patientID]
        ? this.mockCharts[patientID]
        : this.mockCharts["default"];
      this.dialog = true;
    },

    viewScheduledPatient(index) {
      this.modalLabel = "Pending";
      this.modalColor = {
        primary: "#38383d",
        secondary: "#818184",
      };
      this.subjectData = this.scheduledPatients[index];
      this.subjectIndex = index;
      this.subjectSource = "scheduled";
      this.dialog = true;
    },

    schedulePatient() {
      if (this.subjectData.status === "scheduled") {
        this.subjectData.status = "read";
        const patient = this.scheduledPatients.splice(this.subjectIndex, 1)[0];

        // TODO: Add semi-discrete loading indicator
        this.$store.dispatch("updateAlertStatus", {
          alert: get(this.subjectData, "_id.$oid"),
          status: "read",
        });

        if (patient.source === "alerts") {
          patient.status = "read";
          this.alerts.push(patient);
        } else if (patient.source === "patients") {
          this.patients[patient.index].status = "read";
        }
      } else {
        this.subjectData.status = "scheduled";
        this.subjectData.source = this.subjectSource;
        this.subjectData.index = this.subjectIndex;
        this.scheduledPatients.push(this.subjectData);

        if (this.subjectSource === "patients") {
          this.patients[this.subjectIndex].status = "scheduled";
        } else {
          this.alerts.splice(this.subjectIndex, 1);

          // TODO: Add semi-discrete loading indicator
          this.$store.dispatch("updateAlertStatus", {
            alert: this.subjectData._id.$oid,
            status: "scheduled",
          });
        }
      }

      this.dialog = false;
    },

    scheduleText() {
      return this.subjectData.status === "scheduled"
        ? "Unschedule"
        : "Schedule";
    },

    completeAlert() {
      this.scheduledPatients.splice(this.subjectIndex, 1);
      this.$store
        .dispatch("updateAlertStatus", {
          alert: this.subjectData._id.$oid,
          status: "completed",
        })
        .then((data) => {
          this.dialog = false;
        });
    },

    calculateTime(data) {
      let chart_pairs = [];
      let read_timestamp = moment();

      for (let i = data.length - 1; i >= 0; --i) {
        let x_point = read_timestamp && read_timestamp.clone().format("x");
        chart_pairs.unshift([parseInt(x_point, 10), data[i]]);
        read_timestamp.subtract(180, "m");
      }
      return chart_pairs;
    },

    hcOptions(opt = {}) {
      return defaults({}, opt, {
        time: {
          useUTC: false,
        },
        chart: {
          zoomType: "x",
          height: 250,
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            hour: "%H",
          },
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "Click and drag in the plot area to zoom in"
              : "Pinch the chart to zoom in",
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
      });
    },

    randomTimestamp() {
      let randomOffset = Math.floor(Math.random() * Math.floor(120));
      let timeStr = moment().subtract(randomOffset, "minutes");

      return timeStr.format();
    },

    alterTimestamp(obj) {
      if (!obj || !obj.read_timestamp || !obj.patient_id) {
        return;
      }

      if (obj.read_timestamp !== this.initialOffsets[obj.patient_id]) {
        return;
      }

      let ts = this.mockOffsets[obj.patient_id.toLowerCase()];
      if (!!ts) {
        obj.read_timestamp = ts;
      }
    },
  },
};
</script>

<style src="./dialysis.css" scoped></style>
