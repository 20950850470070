<template src="./profile.html"></template>
<script>
export default {
  name: "Profile",
  data() {
    return {
      loading: true,
      error: false,
      user: null,
    };
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx User Profile");
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.$store
        .dispatch("fetchCurrentUser")
        .then((user) => {
          console.log("u?", typeof user, user);
          this.user = user;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
        });
    },
  },
};
</script>

<style src="./profile.css" scoped></style>
