<template src="./patchTable.html"></template>
<script>
export default {
  name: "PatchTable",
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedPatientID: {
      type: String,
      default: null,
    },
    lastRead: {
      type: Boolean,
      default: false,
    },
    listPatients: {
      type: Boolean,
      default: false,
    },
    matchPatient: {
      type: Boolean,
      default: false,
    },
    omitHeaders: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: true,
      error: false,
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 100,
      },
      patches: [],
      selectedPatches: [],
      assignedPatches: [],
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    if (Array.isArray(this.omitHeaders)) {
      if (this.displayColumn("device_id")) {
        this.headers.push({
          text: "Device ID",
          value: "device_id",
          sortable: true,
        });
      }
      if (this.displayColumn("patch_id")) {
        this.headers.push({
          text: "Patch ID",
          value: "patch_id",
          sortable: true,
        });
      }
      if (this.displayColumn("patient_id")) {
        this.headers.push({
          text: "Patient ID",
          value: "patient_id",
          sortable: true,
        });
      }
      if (this.displayColumn("status")) {
        this.headers.push({ text: "Status", value: "status", sortable: true });
      }

      if (this.lastRead) {
        if (this.displayColumn("reference_patient_id")) {
          this.headers.push({ text: "Reference Patient ID", sortable: false });
        }
        if (this.displayColumn("read_timestamp")) {
          this.headers.push({ text: "Latest Read", sortable: false });
        }
      }
    } else {
      if (this.lastRead) {
        this.headers.push({ text: "Reference Patient ID", sortable: false });
        this.headers.push({ text: "Latest Read", sortable: false });
      }
    }
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.assignedPatches = [];

      this.$store
        .dispatch("fetchPatches", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
          sort: this.pagination.sortBy,
          descending: this.pagination.descending,
          lastRead: this.lastRead,
          listPatients: this.listPatients,
        })
        .then((data) => {
          this.patches = data.patches;
          this.total = data.total;
          this.pages = data.pages;

          this.patches.map((patch) => {
            if (patch.patient_id === this.selectedPatientID) {
              this.selectedPatches.push(patch);
              this.assignedPatches.push(patch);
            }

            if (this.lastRead) {
              patch.latest_read = data.reads.find((s) => {
                if (this.listPatients) {
                  return (
                    patch.patient_id ===
                    this.$get(s, "reference_values.patient_id")
                  );
                }
                return (
                  patch.device_id ===
                  this.$get(s, "mq_data.patch_ble_mac", "").replace(/-/g, ":")
                );
              });
            }
          });

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    assignedToOtherPatient(patch) {
      if (!this.selectedPatientID || !patch.patient_id) return false;

      return patch.patient_id !== this.selectedPatientID;
    },

    selectPatch(prop) {
      if (this.link) {
        this.$emit("link-device-id", prop.item);
      }

      if (!this.assignedToOtherPatient(prop.item)) {
        prop.selected = !prop.selected;
      }

      this.emitSelections();
    },

    toggleAll() {
      if (this.selectedPatches.length) {
        this.selectedPatches = [];
      } else {
        this.selectedPatches = this.patches.filter(
          (p) => !p.patient_id || p.patient_id === this.selectedPatientID
        );
      }

      this.emitSelections();
    },

    emitSelections() {
      if (!this.selectable) return false;

      const assignPatches = this.selectedPatches
        .filter((s) => {
          return !this.assignedPatches.find((a) => a.device_id === s.device_id);
        })
        .map((o) => o.device_id);

      const unassignPatches = this.assignedPatches
        .filter((a) => {
          return !this.selectedPatches.find((s) => s.device_id === a.device_id);
        })
        .map((o) => o.device_id);

      this.$emit("assignPatches", assignPatches);
      this.$emit("unassignPatches", unassignPatches);
    },

    changeSort(header) {
      if (!header.sortable) {
        return;
      }

      const column = header.value;
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    displayColumn(name) {
      return !this.omitHeaders.includes(name);
    },
  },
};
</script>

<style src="./patchTable.css" scoped></style>
