<template src="./webssh.html"></template>
<script>
import io from "socket.io-client";
import { Terminal } from "xterm";

export default {
  name: "Webssh",
  data() {
    return {
      loading: true,
      term: null,
      connectToHost: false,
      host: null,
      hosts: [],
      terminalSocket: null,
    };
  },

  mounted() {
    // let xtermScript = document.createElement('xterm');
    // xtermScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    // document.head.appendChild(xtermScript)
  },

  methods: {
    selectHost() {
      console.log("connect to:", this.host);
      this.connectToHost = true;

      // console.log('pid : ' + this.terminal.pid + ' is on ready');
      let terminalContainer = this.$refs.terminal;
      this.term = new Terminal({
        cursorBlink: true,
      });
      this.term.open(terminalContainer);
      // this.$refs.terminal.$el.focus();
      this.term.focus();
      // open websocket
      this.terminalSocket = io(this.$store.state.sshURL, {
        query: `hostname=${this.host}`,
        path: "/webssh/ssh",
        secure: true,
      })
        .on("connect", () => {
          // Pass credentials
          //TODO: creds

          this.term.write("\r\n*** Connected to backend***\r\n");
          // Browser -> Backend
          this.term.on("data", (data) => {
            this.terminalSocket.emit("data", data);
          });
          // Backend -> Browser
          this.terminalSocket.on("data", (data) => {
            this.term.write(data);
          });
          this.terminalSocket.on("disconnect", () => {
            this.term.write("\r\n*** Disconnected from backend***\r\n");

            setTimeout(() => {
              this.term.destroy();
              this.connectToHost = false;
            }, 1000);
          });
        })
        .on("authenticate", () => {
          this.terminalSocket.emit("authenticate", {
            port: "22",
            accessToken: this.$store.state.accessToken,
          });
        })
        .on("authenticate-ssh", (data) => {
          const creds = {
            username: "",
            password: "",
          };

          let prompt = "username";

          this.term.write("login: ");
          this.term.on("key", (key, ev) => {
            // if (data === '\n') {
            //   console.log('new line');
            //   this.term.write('\r\n');
            // }
            // this.term.write(data);
            // console.log('d:', data);

            const printable =
              !ev.altKey && !ev.altGraphKey && !ev.ctrlKey && !ev.metaKey;

            if (ev.keyCode === 13) {
              // console.log(prompt, creds[prompt]);
              if (prompt === "username") {
                this.term.write("\r\npassword: ");
                prompt = "password";
              } else {
                this.term.write("\r\n");
                this.terminalSocket.emit("authenticate", {
                  port: "22",
                  username: creds["username"],
                  password: creds["password"],
                  accessToken: this.$store.state.accessToken,
                });
              }
            } else if (ev.keyCode === 8) {
              // Do not delete the prompt
              if (creds[prompt].length > 0) {
                creds[prompt] = creds[prompt].slice(0, -1);
                this.term.write("\b \b");
              }
            } else if (printable) {
              creds[prompt] += ev.key;

              if (prompt !== "password") {
                this.term.write(key);
              }
            }
          });
        });
      //

      // this.terminalSocket.onopen = this.runRealTerminal;
      // this.terminalSocket.onclose = this.closeRealTerminal;
      // this.terminalSocket.onerror = this.errorRealTerminal;
      // this.term.attach(this.terminalSocket);
      this.term._initialized = true;
    },

    runRealTerminal() {
      //
    },

    closeRealTerminal() {
      //
    },

    errorRealTerminal() {
      //
    },
  },
};
</script>

<style src="./webssh.css" scoped></style>
