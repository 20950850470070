<template src="./hub-command.html"></template>
<script>
export default {
  name: "HubCommand",
  data() {
    return {
      hub: {},
      device_id: "",
      args: "",
      otherCommand: "",
    };
  },

  created() {
    this.device_id = this.$route.params.device_id;
    this.routed_from = this.$route.params.routed_from;
  },

  methods: {
    createCommand(command) {
      const comm = {
        command: command,
        hub_device_id: this.device_id,
        arguments: this.args,
      };

      if (command === "other") {
        comm.command = this.otherCommand;
      }

      this.$store.dispatch("createHubCommand", comm).then(() => {
        //TODO: This is a fairly ugly hack, but without it, the pending commands page seems to be loaded before
        // the new command has been saved; this *shouldn't* be the case, so investigate why and fix it!
        setTimeout(() => {
          this.$router.push({
            name: "HubPendingCommands",
            params: {
              device_id: this.device_id,
              routed_from: this.routed_from,
            },
          });
        }, 100);
      });
    },
  },
};
</script>

<style src="./hub-command.css" scoped></style>
