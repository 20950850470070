<template src="./iris-read-charts.html"></template>
<script>
import { defaults, get } from "lodash";
import moment from "moment";

export default {
  name: "IrisReadCharts",

  props: {
    read: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      charts: [],
    };
  },

  created() {
    this.buildCharts();
  },

  methods: {
    buildCharts() {
      const micSampleRate = get(
        this.read,
        "decoded_data.mic_daq_params.sample_rate[0]",
        -1
      );
      const accelMicSampleRate = get(
        this.read,
        "decoded_data.acceleration_daq_params_during_mic.sample_rate",
        -1
      );
      const accelPPGleftSampleRate = get(
        this.read,
        "decoded_data.acceleration_daq_params_during_ppg.left.sample_rate",
        -1
      );
      const accelPPGrightSampleRate = get(
        this.read,
        "decoded_data.acceleration_daq_params_during_ppg.right.sample_rate",
        -1
      );
      const ppgLeftSampleRate = get(
        this.read,
        "decoded_data.common_ppg_daq_parameter.left.sample_rate",
        -1
      );
      const ppgRightSampleRate = get(
        this.read,
        "decoded_data.common_ppg_daq_parameter.right.sample_rate",
        -1
      );

      // TODO: Update for 16 channel patches
      // Highcharts stuff
      // Accumulative temperature charts
      // TODO: Test this on an iris/sunflower patch, if possible
      const temps = get(this.read, "decoded_data.accum_temp_data.temps", []);
      const tempSeries = [];

      for (const temp of temps) {
        if (!temp || !Array.isArray(temp) || !temp.length) {
          continue;
        }

        let timestamp;

        temp.forEach((t, i) => {
          const j = i - 1;

          if (!timestamp && typeof t === "string") {
            const timestampFormats = [
              moment.ISO_8601,
              "ddd MMM  D HH:mm:ss YYYY",
              "ddd MMM D HH:mm:ss YYYY",
            ];
            timestamp = moment(t, timestampFormats).valueOf();

            if (isNaN(timestamp)) {
              timestamp = false;
            }
          } else if (typeof t === "number") {
            if (!tempSeries[j]) {
              tempSeries[j] = {
                type: "line",
                name: i,
                color: Highcharts.getOptions().colors[i],
                data: [],
              };
            }

            if (timestamp) {
              tempSeries[j].data.push([timestamp, t]);
            }
          }
        });
      }

      if (tempSeries.length) {
        this.charts.push(
          this.hcOptions({
            title: {
              text: "Accumulative temperature",
            },
            yAxis: {
              title: {
                text: "Celsius",
              },
            },
            xAxis: {
              type: "datetime",
              labels: {
                // format: '{value:%Y-%b-%e %l:%M %p}'
              },
              gridLineWidth: 1,
            },
            tooltip: {
              xDateFormat: "%Y-%b-%e %l:%M %p",
            },
            legend: {
              enabled: true,
            },
            series: tempSeries,
          })
        );
      }
      // Mic
      const micData = get(
        this.read,
        "decoded_data.uncompressed_mic_data.processed",
        []
      );
      if (micData.length) {
        this.charts.push(
          this.hcOptions({
            title: {
              text: "Microphone",
            },
            yAxis: {
              title: {
                text: "Volts",
              },
            },
            series: [
              {
                type: "line",
                name: "volts",
                data: this.calculateTime(micData, micSampleRate),
              },
            ],
          })
        );
      }

      // Accel during mic
      const accelData = get(
        this.read,
        "decoded_data.acceleration_data_during_mic.processed",
        []
      );
      if (accelData.length) {
        const accelSeries = this.accelSeries(accelData, accelMicSampleRate);
        this.charts.push(
          this.hcOptions({
            title: {
              text: "Acceleration during mic",
            },
            yAxis: {
              title: {
                text: "G",
              },
            },
            series: accelSeries,
          })
        );
      }

      // Accel ppg left
      const accelPPGleftData = get(
        this.read,
        "decoded_data.acceleration_data_during_ppg.left.processed",
        []
      );
      if (accelPPGleftData.length) {
        const accelPPGleftSeries = this.accelSeries(
          accelPPGleftData,
          accelPPGleftSampleRate
        );
        this.charts.push(
          this.hcOptions({
            title: {
              text: "Acceleration during PPG (left)",
            },
            yAxis: {
              title: {
                text: "G",
              },
            },
            series: accelPPGleftSeries,
          })
        );
      }

      // Accel ppg right
      const accelPPGrightData = get(
        this.read,
        "decoded_data.acceleration_data_during_ppg.right.processed",
        []
      );
      if (accelPPGrightData.length) {
        const accelPPGrightSeries = this.accelSeries(
          accelPPGrightData,
          accelPPGrightSampleRate
        );
        this.charts.push(
          this.hcOptions({
            title: {
              text: "Acceleration during PPG (right)",
            },
            yAxis: {
              title: {
                text: "G",
              },
            },
            series: accelPPGrightSeries,
          })
        );
      }

      // PPG labels
      const ppgLabel = {
        left0: "Red left",
        left1: "IR Near left",
        left2: "G/Amb left",
        left3: "IR Far left",
        right0: "Red right",
        right1: "IR Far right",
        right2: "G/Amb right",
        right3: "IR Near right",
      };

      // PPG left
      const ppgLeft = get(this.read, "decoded_data.ppg_data.left", {});
      Object.entries(ppgLeft).forEach(([key, value]) => {
        this.charts.push(
          this.hcOptions({
            title: {
              text: ppgLabel["left" + key],
            },
            yAxis: {
              title: {
                text: "Counts",
              },
              reversed: this.reversePPG("left" + key),
            },
            series: [
              {
                type: "line",
                name: `${key}`,
                data: this.calculateTime(value.processed, ppgLeftSampleRate),
              },
            ],
          })
        );
      });

      // PPG right
      const ppgRight = get(this.read, "decoded_data.ppg_data.right", {});
      Object.entries(ppgRight).forEach(([key, value]) => {
        this.charts.push(
          this.hcOptions({
            title: {
              text: ppgLabel["right" + key],
            },
            yAxis: {
              title: {
                text: "Counts",
              },
              reversed: this.reversePPG("right" + key),
            },
            series: [
              {
                type: "line",
                name: `${key}`,
                data: this.calculateTime(value.processed, ppgRightSampleRate),
              },
            ],
          })
        );
      });
    },

    calculateTime(data, rate = 1000, offset = 0) {
      const MS_PER_HERTZ = 1000;
      let ms = MS_PER_HERTZ / rate;
      let offsetMS = ms * offset;
      let chart_pairs = [];
      let read_timestamp = get(this.read, "decoded_data.daq_header.datetime");

      if (!read_timestamp || !data || !Array.isArray(data) || rate === -1) {
        return data;
      }

      for (let i = 0; i < data.length; i++) {
        let xPoint = ms * i + offsetMS;
        chart_pairs.push([xPoint, data[i]]);
        read_timestamp += ms;
      }

      return chart_pairs;
    },

    hcOptions(opt = {}) {
      return defaults({}, opt, {
        chart: {
          zoomType: "x",
        },
        xAxis: {
          labels: {
            format: "{value:%M:%S.%L}",
          },
          gridLineWidth: 1,
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "Click and drag in the plot area to zoom in"
              : "Pinch the chart to zoom in",
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${moment(this.x).format("mm:ss.SS")}<br>${
              this.series.name
            }: ${this.y}`;
          },
        },
      });
    },

    accelSeries(data, rate) {
      const OFFSET = 9;
      if (data.length > OFFSET) {
        data.splice(0, OFFSET);
      }

      const factor = [];

      for (let i = 1; i < data.length; i += 3) {
        let x = data[i - 1];
        let y = data[i];
        let z = data[i + 1];

        factor.push(
          Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2))
        );
      }

      return [
        {
          type: "line",
          name: "1",
          data: this.calculateTime(factor, rate, OFFSET),
        },
      ];
    },

    /**
     * Determine if the PPG channel graph should be inverted.
     * @param channel
     * @returns {boolean}
     */
    reversePPG(channel) {
      const ppgReverse = {
        left0: "results_dict_left.red.mult",
        left1: "results_dict_left.ir_near.mult",
        left2: false,
        left3: "results_dict_left.ir_far.mult",
        right0: "results_dict_right.red.mult",
        right1: "results_dict_right.ir_far.mult",
        right2: false,
        right3: "results_dict_right.ir_near.mult",
      };

      if (!ppgReverse[channel]) {
        return false;
      }

      return get(this.result, ppgReverse[channel]) === -1;
    },
  },
};
</script>

<style src="./iris-read-charts.css" scoped></style>
