<template src="./hubTable.html"></template>
<script>
export default {
  name: "HubTable",
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedPatientID: {
      type: String,
      default: null,
    },
    displayControls: {
      type: Boolean,
      default: false,
    },
    omitHeaders: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: true,
      error: false,
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 100,
      },
      hubs: [],
      selectedHubs: [],
      assignedHubs: [],
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    if (Array.isArray(this.omitHeaders)) {
      if (this.displayColumn("device_id")) {
        this.headers.push({
          text: "Device ID",
          value: "device_id",
          sortable: true,
        });
      }
      if (this.displayColumn("hub_id")) {
        this.headers.push({ text: "GSN", value: "hub_id", sortable: true });
      }
      if (this.displayColumn("vpn_hostname")) {
        this.headers.push({
          text: "VPN Hostname",
          value: "vpn_hostname",
          sortable: true,
        });
      }
      if (this.displayColumn("vpn_ip")) {
        this.headers.push({
          text: "IP Address",
          value: "vpn_ip",
          sortable: true,
        });
      }
      if (this.displayColumn("hub_hardware")) {
        this.headers.push({
          text: "Hardware",
          value: "hub_hardware",
          sortable: true,
        });
      }
      if (this.displayColumn("hub_firmware")) {
        this.headers.push({
          text: "Firmware",
          value: "hub_firmware",
          sortable: true,
        });
      }
      if (this.displayColumn("hub_software")) {
        this.headers.push({
          text: "Software",
          value: "hub_software",
          sortable: true,
        });
      }
      if (this.displayColumn("device_type")) {
        this.headers.push({
          text: "Device Type",
          value: "device_type",
          sortable: true,
        });
      }
      if (this.displayColumn("modem_type")) {
        this.headers.push({
          text: "Modem Type",
          value: "modem_type",
          sortable: true,
        });
      }
      if (this.displayColumn("data_plan")) {
        this.headers.push({
          text: "Data Plan",
          value: "data_plan",
          sortable: true,
        });
      }
      if (this.displayColumn("patient_id")) {
        this.headers.push({
          text: "Patient",
          value: "patient_id",
          sortable: true,
        });
      }
      if (this.displayColumn("sim_card_number")) {
        this.headers.push({
          text: "SIM Card #",
          value: "sim_card_number",
          sortable: true,
        });
      }
      if (this.displayColumn("imei")) {
        this.headers.push({ text: "IMEI", value: "imei", sortable: true });
      }
      if (this.displayColumn("timezone")) {
        this.headers.push({
          text: "Timezone",
          value: "timezone",
          sortable: true,
        });
      }
    }
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.assignedHubs = [];

      this.$store
        .dispatch("fetchHubs", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
          sort: this.pagination.sortBy,
          descending: this.pagination.descending,
        })
        .then((data) => {
          this.hubs = data.hubs;
          this.total = data.total;
          this.pages = data.pages;

          this.hubs.map((hub) => {
            if (hub.patient_id === this.selectedPatientID) {
              this.selectedHubs.push(hub);
              this.assignedHubs.push(hub);
            }
          });

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    assignedToOtherPatient(patch) {
      if (!this.selectedPatientID || !patch.patient_id) return false;

      return patch.patient_id !== this.selectedPatientID;
    },

    selectHub(prop) {
      if (this.link) {
        this.$emit("link-device-id", prop.item);
      }

      if (!this.assignedToOtherPatient(prop.item)) {
        this.selectedHubs = [];

        if (!prop.selected) {
          this.hubs.forEach((item) => {
            if (item.device_id === prop.item.device_id) {
              this.selectedHubs.push(item);
            }
          });
        }
      }

      this.emitSelections();
    },

    toggleAll() {
      if (this.selectedHubs.length) {
        this.selectedHubs = [];
      }

      this.emitSelections();
    },

    emitSelections() {
      if (!this.selectable) return false;

      const assignPatches = this.selectedHubs
        .filter((s) => {
          return !this.assignedHubs.find((a) => a.device_id === s.device_id);
        })
        .map((o) => o.device_id);

      const unassignPatches = this.assignedHubs
        .filter((a) => {
          return !this.selectedHubs.find((s) => s.device_id === a.device_id);
        })
        .map((o) => o.device_id);

      this.$emit("assignPatches", assignPatches);
      this.$emit("unassignPatches", unassignPatches);
    },

    changeSort(header) {
      if (!header.sortable) {
        return;
      }

      const column = header.value;
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    displayColumn(name) {
      return !this.omitHeaders.includes(name);
    },
  },
};
</script>

<style src="./hubTable.css" scoped></style>
