<template src="./patient-list.html"></template>
<script>
export default {
  name: "PatientList",

  created() {
    this.$store.commit("changeTitle", "Graftworx Patches");
  },

  methods: {
    routePatient(row) {
      if (!row && !row.patient_id) {
        return;
      }

      this.$router.push({
        name: "PatientReads",
        params: {
          patient_id: row.patient_id,
        },
      });
    },
  },
};
</script>

<style src="./patient-list.css" scoped></style>
