<template src="./tuvalu-read-charts.html"></template>
<script>
import { defaults, get } from "lodash";
import Highcharts from "highcharts";
import moment from "moment";

export default {
  name: "TuvaluReadCharts",

  props: {
    read: {
      type: Object,
      default: () => {},
    },
    tz: {
      type: Number,
      default: 0,
      required: true,
    },
    targetTZ: {
      type: String,
      default: "hub",
      required: true,
    },
  },

  data() {
    return {
      tempChart: null,
      charts: [],
      ppgRows: [],
    };
  },

  computed: {
    binding() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },

    tz_change() {
      return this.tz;
    },
  },

  watch: {
    targetTZ: {
      deep: true,
      handler() {
        this.buildTempChart();
      },
    },
  },

  created() {
    this.buildCharts();
    this.buildTempChart();
  },

  methods: {
    buildCharts() {
      // Highcharts stuff
      // PPG charts
      const frameData = get(this.read, "decoded_data.sensor_frames", []);
      for (const frame of frameData) {
        if (!frame) {
          continue;
        }

        // Mic
        const micChannels = get(frame, "mic_channels", []);

        micChannels.forEach((channel, i) => {
          this.charts.push(
            this.hcOptions({
              title: {
                text: `Microphone (frame ${frame.frame_number}, channel ${i})`,
              },
              yAxis: {
                title: {
                  text: "Volts",
                },
              },
              series: [
                {
                  type: "line",
                  name: "volts",
                  data: this.calculateTime(channel.data, channel.sample_rate),
                },
              ],
            })
          );
        });

        // Accel
        const accelChannels = get(frame, "accel_channels", []);

        for (const channel of accelChannels) {
          const accelSeries = this.accelSeries(
            channel.data,
            channel.sample_rate
          );
          let accel_title;
          if (frame.frame_number === 1) {
            accel_title = "during mic";
          } else if (frame.frame_number === 2) {
            accel_title = "during PPG";
          } else {
            accel_title = "";
          }
          this.charts.push(
            this.hcOptions({
              title: {
                text: `Acceleration ${accel_title}`,
              },
              yAxis: {
                title: {
                  text: "G",
                },
              },
              series: accelSeries,
            })
          );
        }

        // PPG
        const ppgChannels = get(frame, "ppg_channels", []);
        var colors = [];
        var names = [];

        for (const channel of ppgChannels) {
          if (!this.ppgRows[channel.coord[1]]) {
            this.ppgRows[channel.coord[1]] = [];
          }

          const sampleRate = channel.sample_rate
            ? channel.sample_rate
            : frame.sample_rate;
          let name;
          let color;

          if (channel.wavelength === 0) {
            name = "Ambient";
            color = "#492970";
          } else if (492 <= channel.wavelength && channel.wavelength <= 577) {
            name = "Green";
            color = Highcharts.getOptions().colors[2];
          } else if (622 <= channel.wavelength && channel.wavelength <= 780) {
            name = "Red";
            color = Highcharts.getOptions().colors[5];
          } else if (780 < channel.wavelength) {
            name = "IR";
            color = Highcharts.getOptions().colors[1];
          } else {
            name = "Other";
            color = Highcharts.getOptions().colors[0];
          }

          if (!colors.includes(color)) {
            colors.push(color);
            names.push(name);
          }

          if (this.ppgRows[channel.coord[1]][channel.coord[0]]) {
            const chart = this.ppgRows[channel.coord[1]][channel.coord[0]];

            chart.title.text += ` / ${channel.refdes} (gain ${channel.gain})`;

            chart.series.push({
              type: "line",
              name: name,
              color: color,
              data: this.calculateTime(channel.data, sampleRate, 0, 1000),
            });
          } else {
            this.ppgRows[channel.coord[1]][channel.coord[0]] = this.hcOptions({
              title: {
                text: `${channel.refdes} (gain ${channel.gain})`,
              },
              subtitle: {
                text: channel.coord,
              },
              yAxis: {
                title: {
                  text: "uA",
                  style: {
                    color: "black",
                  },
                },
              },
              series: [
                {
                  type: "line",
                  yAxis: 0,
                  name: name,
                  color: color,
                  data: this.calculateTime(channel.data, sampleRate, 0, 1000),
                },
              ],
            });
          }
        }
        // Add standalone legend

        if (ppgChannels.length > 0) {
          var zip = colors.map(function (e, i) {
            return [e, names[i]];
          });
          const lastRow = this.ppgRows.length;
          this.ppgRows[lastRow] = [];
          this.ppgRows[lastRow][0] = this.hcOptions({
            title: {
              text: `PPG channel legend`,
            },
            yAxis: {
              title: {
                text: "Channels",
                style: {
                  color: "black",
                },
              },
            },
            plotOptions: {
              line: {
                marker: {
                  enabled: false,
                },
              },
            },
            legend: {
              enabled: true,
            },
          });
          const legend = this.ppgRows[lastRow][0];
          legend.series = [];
          var num_chan = 0;
          for (const entry of zip) {
            num_chan += 1;
            legend.series.push({
              type: "line",
              name: entry[1],
              color: entry[0],
              data: [
                [0, num_chan],
                [1, num_chan],
                [2, num_chan],
              ],
            });
          }
        }
      }
    },

    buildTempChart() {
      // Accumulative temperature charts
      const temps = get(this.read, "decoded_data.accum_temp_data.temps", []);
      const tempSeries = [];

      for (const temp of temps) {
        if (!temp || !Array.isArray(temp) || !temp.length) {
          continue;
        }

        let timestamp;

        temp.forEach((t, i) => {
          const j = i - 1;

          if (!timestamp && typeof t === "string") {
            const timestampFormats = [
              moment.ISO_8601,
              "ddd MMM  D HH:mm:ss YYYY",
              "ddd MMM D HH:mm:ss YYYY",
            ];
            let timeStr = moment(t, timestampFormats).valueOf();
            let hub_offset = get(this.read, "mq_data.hub_tz_offset");
            let baseTZ = new Date().getTimezoneOffset() * 1;
            let tzMinutes;
            switch (this.targetTZ) {
              case "local":
                let localTZ = new Date(timeStr).getTimezoneOffset() * 1;
                tzMinutes = localTZ + baseTZ;
                break;
              case "hub":
                let hubTZ = this.tzToMinutes(hub_offset);
                tzMinutes = baseTZ - hubTZ;
                break;
              case "utc":
                let utcTZ = baseTZ;
                tzMinutes = utcTZ;
                break;
            }
            timeStr = moment(timeStr).subtract(tzMinutes, "minutes");
            timestamp = moment(timeStr, timestampFormats).valueOf();

            if (isNaN(timestamp)) {
              timestamp = false;
            }
          } else if (typeof t === "number") {
            if (!tempSeries[j]) {
              tempSeries[j] = {
                type: "line",
                name: i,
                color: Highcharts.getOptions().colors[i],
                data: [],
              };
            }

            if (timestamp) {
              tempSeries[j].data.push([timestamp, t]);
            }
          }
        });
      }

      if (tempSeries.length) {
        let showLineMarkers = false;

        if (tempSeries[0] && Array.isArray(tempSeries[0].data)) {
          showLineMarkers = tempSeries[0].data.length === 1;
        }

        this.tempChart = this.hcOptions({
          title: {
            text: "Temperature",
          },
          yAxis: {
            title: {
              text: "Celsius",
            },
          },
          xAxis: {
            type: "datetime",
            labels: {
              // format: '{value:%Y-%b-%e %l:%M %p}'
            },
            gridLineWidth: 1,
          },
          tooltip: {
            xDateFormat: "%Y-%b-%e %l:%M %p",
          },
          plotOptions: {
            line: {
              marker: {
                enabled: showLineMarkers,
              },
            },
          },
          legend: {
            enabled: true,
          },
          series: tempSeries,
        });
      }
    },

    tzToMinutes(tz) {
      const sign = tz.charAt(0);
      const hours = parseInt(tz.substr(1, 2));
      const minutes = parseInt(tz.substr(3));
      let total = hours * 60 + minutes;

      if (sign === "-") {
        total *= -1;
      }

      return total;
    },

    calculateTime(data, rate = 1000, offset = 0, yAdjust = 1) {
      const MS_PER_HERTZ = 1000;
      let ms = MS_PER_HERTZ / rate;
      let offsetMS = ms * offset;
      let chart_pairs = [];
      let read_timestamp = get(this.read, "decoded_data.daq_header.datetime");
      if (!read_timestamp || !data || !Array.isArray(data) || rate === -1) {
        return data;
      }
      for (let i = 0; i < data.length; i++) {
        let xPoint = ms * i + offsetMS;
        chart_pairs.push([xPoint, data[i] * yAdjust]);
        read_timestamp += ms;
      }
      return chart_pairs;
    },

    hcOptions(opt = {}) {
      return defaults({}, opt, {
        chart: {
          zoomType: "x",
        },
        xAxis: {
          labels: {
            format: "{value:%M:%S.%L}",
          },
          gridLineWidth: 1,
        },
        subtitle: {
          // text: document.ontouchstart
          // === undefined ? 'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${moment(this.x).format("mm:ss.SS")}<br>${
              this.series.name
            }: ${this.y}`;
          },
        },
      });
    },

    accelSeries(data, rate) {
      const OFFSET = 9;
      if (data.length > OFFSET) {
        data.splice(0, OFFSET);
      }

      const factor = [];

      for (let i = 1; i < data.length; i += 3) {
        let x = data[i - 1];
        let y = data[i];
        let z = data[i + 1];

        factor.push(
          Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2))
        );
      }

      return [
        {
          type: "line",
          name: "1",
          data: this.calculateTime(factor, rate, OFFSET),
        },
      ];
    },
  },
};
</script>

<style src="./tuvalu-read-charts.css" scoped></style>
