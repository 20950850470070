<template src="./login.html"></template>
<script>
export default {
  name: "Login",
  data() {
    return {
      loading: true,
    };
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx Login");
    this.authenticate();
  },

  methods: {
    authenticate() {
      this.$store
        .dispatch("authenticate", this.$route.query.code)
        .then(() => {
          console.log("user authenticated");
          this.$store.dispatch("fetchCurrentUser");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style src="./login.css" scoped></style>
