<template src="./user-management.html"></template>
<script>
export default {
  name: "UserManagement",
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: null,
      newUserDialog: false,
      valid: false,
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 100,
      },
      saving: false,
      viewUserDialog: false,
      selectedUser: null,
      users: [],
      rowsPerPageOptions: [10, 50, 100, 200],
      headers: [
        { text: "Email", value: "email" },
        { text: "Enabled", value: "enabled" },
        { text: "Status", value: "status" },
        { text: "Email Verified", value: "email_verified" },
        { text: "Organization", value: "custom:organization" },
        { text: "ACL Group", value: "custom:acl_group" },
      ],
      aclGroups: [
        { text: "User", value: "user" },
        { text: "User Admin", value: "user_admin" },
        { text: "Admin", value: "admin" },
      ],

      // New user
      email: "",
      password: "",
      organization: "",
      phone: "",
      aclGroup: "user",
      sendInvitation: true,
      sendSMS: false,
      sendEmail: true,
      verifiedPhone: false,
      verifiedEmail: false,

      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          (v && v.length <= 255) || "Email must be 255 characters or fewer",
      ],
      passwordRules: [
        (v) =>
          (!!v ? v.length >= 6 : true) ||
          "Password must be at least 6 characters",
      ],
      organizationRules: [],

      emailErrors: [],
      passwordErrors: [],
      organizationErrors: [],
      phoneErrors: [],
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx Users");
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.$store
        .dispatch("fetchUsers", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
        })
        .then((data) => {
          this.users = data.users;
          this.total = data.total;
          this.pages = data.pages;

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    createUser() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("createUser", {
          email: this.email,
          password: this.password,
          organization: this.organization,
          phone: this.phone,
          aclGroup: this.aclGroup,
          sendInvitation: this.sendInvitation,
          sendSMS: this.sendSMS,
          sendEmail: this.sendEmail,
          verifiedPhone: this.verifiedPhone,
          verifiedEmail: this.verifiedEmail,
        })
        .then((newUser) => {
          this.users.push(newUser);
          this.clear();
        })
        .catch((err) => {
          if (this.$get(err, "response.data.message") === "duplicate key") {
            this.emailErrors.push("email must be unique");
          }

          this.saving = false;
        });
    },

    updateUser() {
      this.saving = true;
      this.$store
        .dispatch("updateUser", this.selectedUser)
        .then(() => {
          this.viewUserDialog = false;
          this.saving = false;
          this.selectedUser = null;
        })
        .catch((err) => {
          this.errorMessage = this.$get(err, "response.data.message");
          this.saving = false;
          this.error = true;
        });
    },

    resetPassword() {
      this.saving = true;
      this.$store
        .dispatch("resetPassword", {
          username: this.selectedUser.username,
        })
        .then((r) => {
          this.viewUserDialog = false;
          this.saving = false;
          this.selectedUser = null;
        })
        .catch((err) => {
          this.errorMessage = this.$get(err, "response.data.message");
          this.saving = false;
          this.error = true;
        });
    },

    clear() {
      this.error = false;
      this.errorMessage = null;
      this.newUserDialog = false;
      this.saving = false;
      this.$refs.form.reset();
      this.aclGroup = "user";
      this.sendInvitation = true;
      this.sendEmail = true;
    },

    viewUser(user) {
      this.selectedUser = user;
      this.viewUserDialog = true;
    },

    deleteUser() {
      this.saving = true;
      this.$store
        .dispatch("deleteUser", {
          user: this.selectedUser.username,
        })
        .then((r) => {
          this.viewUserDialog = false;
          this.saving = false;
          this.selectedUser = null;

          //TODO: remove user from list
        })
        .catch((err) => {
          this.errorMessage = this.$get(err, "response.data.message");
          this.saving = false;
          this.error = true;
        });
    },
  },
};
</script>

<style src="./user-management.css" scoped></style>
