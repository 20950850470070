<template src="./kit-search.html"></template>
<script>
export default {
  name: "KitSearch",

  props: {
    query: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    kits: [],
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),

  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.kits.length > 0) return;

      // Items have already been requested
      this.$emit("query", val);

      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      this.getDataFromApi();
    },
  },

  methods: {
    getDataFromApi() {
      const q = encodeURI(this.search);
      this.$store.dispatch("searchKits", { q: q }).then((data) => {
        this.kits = data.kits.map((kit) => {
          return [kit.kit_id, kit.subject_id];
        });

        this.isLoading = false;
      });
    },
  },
};
</script>

<style src="./kit-search.css" scoped></style>
