<template src="./dashboard.html"></template>
<script>
export default {
  name: "Dashboard",
  data() {
    return {
      docsUrl: `${this.$store.state.apiUrl}/api/docs`,
    };
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx Portal");
  },
};
</script>

<style src="./dashboard.css" scoped></style>
