import { render, staticRenderFns } from "./ground-truth.html?vue&type=template&id=1ac7e641&scoped=true&"
import script from "./GroundTruth.vue?vue&type=script&lang=js&"
export * from "./GroundTruth.vue?vue&type=script&lang=js&"
import style0 from "./ground-truth.css?vue&type=style&index=0&id=1ac7e641&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac7e641",
  null
  
)

export default component.exports