<template src="./hub-search.html"></template>
<script>
export default {
  name: "HubSearch",

  props: {
    query: {
      type: String,
      default: null,
    },
    environment: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    hubs: [],

    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),

  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.hubs.length > 0) return;

      // Items have already been requested
      this.$emit("query", val);

      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      this.getDataFromApi();
    },
  },

  methods: {
    getDataFromApi() {
      const q = encodeURI(this.search);
      this.$store
        .dispatch("searchHubs", { q: q, environment: this.environment })
        .then((data) => {
          this.hubs = data.hubs.map((hub) => {
            return [hub.device_id, hub.hub_id, hub.vpn_hostname].join(" ");
          });

          this.isLoading = false;
        });
    },
  },
};
</script>

<style src="./hub-search.css" scoped></style>
