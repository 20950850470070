<template src="./graftworx-header.html"></template>
<script>
export default {
  name: "GraftworxHeader",

  computed: {
    environment() {
      return this.$store.state.environment;
    },
  },
};
</script>

<style src="./graftworx-header.css" scoped></style>
