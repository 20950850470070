<template src="./app.html"></template>
<script>
export default {
  name: "App",

  computed: {
    authenticated() {
      if (!this.$store.state.loginURL) {
        return false;
      }

      if (this.$router.currentRoute.name === "Login") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$store.state.authenticating = true;
        return true;
      }

      return this.$store.state.authenticated;
    },
  },

  created() {
    this.$store.dispatch("fetchEnvironment").then((data) => {
      this.$store.commit("setAuthenticationURLs", data);

      if (this.$router.currentRoute.name !== "Login") {
        this.$store.dispatch("restoreAuthentication").then(() => {
          this.$store.dispatch("fetchCurrentUser");
        });
      }
    });
  },
};
</script>

<style src="./app.css" scoped></style>
