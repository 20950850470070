<template src="./keys.html"></template>
<script>
import moment from "moment";

export default {
  name: "Keys",

  filters: {
    time(value) {
      if (!value || typeof value !== "string") return;

      return moment(value).format("YYYY-MM-DD h:mm:ssa");
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      error: false,
      keys: [],
      newKeyDialog: false,
      newKey: {},
      valid: true,
      rowsPerPageOptions: [10, 50, 100, 200],
      pagination: {
        rowsPerPage: 100,
      },
      headers: [
        { text: "Key", value: "key" },
        { text: "Active", value: "active" },
        { text: "Comment", value: "comment" },
        { text: "User ID", value: "user" },
        { text: "Created At", value: "created_date" },
      ],
      total: 0,
      pages: 0,

      rules: {},
      errors: {},
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx API Key Management");
    this.clear();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.$store
        .dispatch("fetchKeys", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
        })
        .then((data) => {
          this.keys = data.keys;
          this.total = data.total;
          this.pages = data.pages;

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    clear() {
      this.newKeyDialog = false;
      this.saving = false;
      // this.newKey = {};

      // this.rules = {
      //   device_id: []
      // };
      //
      // this.errors = {
      //   device_id: []
      // };
    },

    createKey() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      this.$store
        .dispatch("createKey", this.newKey)
        .then((newKey) => {
          this.keys.push(newKey);
          this.clear();
        })
        .catch((err) => {
          this.saving = false;
        });
    },

    updateKey(key) {
      this.saving = true;
      this.loading = true;

      this.$store
        .dispatch("updateKey", key)
        .then((updatedKey) => {
          key = updatedKey;
          this.saving = false;
          this.loading = false;
        })
        .catch((err) => {
          // TODO: Display some sort of error
          this.saving = false;
          this.loading = false;
        });
    },

    parseDate(value) {
      if (!value) return;

      return moment(value).format("YYYY-MM-DD h:mm:ssa");
    },
  },
};
</script>

<style src="./keys.css" scoped></style>
