<template src="./hubs.html"></template>
<script>
import moment from "moment";

export default {
  name: "Hubs",

  filters: {
    time(value) {
      if (!value || typeof value !== "string") return;

      return moment(value).format("YYYY-MM-DD h:mm:ssa");
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      error: false,
      hubs: [],
      newHubDialog: false,
      importHubDialog: false,
      importHubs: [],
      environment: "generic",
      newHub: {},
      valid: true,
      rowsPerPageOptions: [10, 50, 100, 200],
      pagination: {
        rowsPerPage: 100,
      },
      headers: [
        { text: "Device ID", value: "device_id" },
        { text: "GSN", value: "hub_id" },
        { text: "VPN Hostname", value: "vpn_hostname" },
        { text: "IP Address", value: "vpn_ip" },
        { text: "Hardware", value: "hub_hardware" },
        { text: "Firmware", value: "hub_firmware" },
        { text: "Software", value: "hub_software" },
        { text: "Device Type", value: "device_type" },
        { text: "Modem Type", value: "modem_type" },
        { text: "Data Plan", value: "data_plan" },
        { text: "Patient", value: "patient_id" },
        { text: "SIM Card #", value: "sim_card_number" },
        { text: "IMEI", value: "imei" },
        { text: "Timezone", value: "timezone" },
      ],
      total: 0,
      pages: 0,

      rules: {},
      errors: {},
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit("changeTitle", "Graftworx Hubs");
    this.clear();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;

      this.$store
        .dispatch("fetchHubs", {
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage,
          sort: this.pagination.sortBy,
          descending: this.pagination.descending,
        })
        .then((data) => {
          this.hubs = data.hubs;
          this.total = data.total;
          this.pages = data.pages;

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    clear() {
      this.newHubDialog = false;
      this.saving = false;
      this.newHub = {};

      this.rules = {
        device_id: [],
      };

      this.errors = {
        device_id: [],
      };
    },

    createHub() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("createHub", this.newHub)
        .then((newHub) => {
          this.hubs.push(newHub);
          this.clear();
        })
        .catch((err) => {
          // if (this.$get(err, 'response.data.message') === 'duplicate key') {
          //   this.emailErrors.push('email must be unique');
          // }

          this.saving = false;
        });
    },

    updateHub(hub) {
      this.saving = true;
      this.loading = true;
      this.$store
        .dispatch("updateHub", hub)
        .then((updatedHub) => {
          hub = updatedHub;
          this.saving = false;
          this.loading = false;
        })
        .catch((err) => {
          // TODO: Display some sort of error
          this.saving = false;
          this.loading = false;
        });
    },

    openHubDialog() {
      this.importHubDialog = true;
    },

    saveHubImport() {
      if (!this.importHubs.length) {
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("importHub", {
          hubs: [this.importHubs[0]],
        })
        .then((data) => {
          console.log("data:", data);
          this.importHubDialog = false;
          this.saving = false;
        });
    },

    onOrOff(hub) {
      let scheduled = false;

      if (hub.light_schedule) {
        const schedule = moment(hub.light_schedule);
        scheduled = moment().isAfter(schedule);
      }

      return hub.light_on || scheduled ? "turn light off" : "force light on";
    },

    changeSort(header) {
      const column = header.value;
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    toggleLight(hub) {
      if (!hub) {
        return;
      }

      let scheduled = false;

      if (hub.light_schedule) {
        const schedule = moment(hub.light_schedule);
        scheduled = moment().isAfter(schedule);
      }

      if (scheduled) {
        hub.light_schedule = null;
      }

      if (hub.light_on) {
        hub.light_on = false;
      } else if (!scheduled) {
        hub.light_on = true;
      }

      this.updateHub(hub);
    },
  },
};
</script>

<style src="./hubs.css" scoped></style>
